
// contact us block

.contact_us{
  width: 100%;
  height: auto;
  background-color: $color-white;
  &_inner{
    height: auto;
    max-width: $site-width;
    @include media('xs') {
      min-height: 370px;
      width: 100%;
    }
    @include media('sm') {
      min-height: 370px;
      width: 100%;
    }
    @include media('md') {
      min-height: 570px;
      max-width: $site-width;
    }
    margin: 0 auto;
    padding: rem(0px) rem(32px);
    &_left{
      width: 50%;
      float: left;
      background-image: url(images/contact_bg_right.png), url(images/contact_bg_left.png);
      background-position: 100% 75%, -10% 37%;
      background-repeat: no-repeat;
      @include media('xs') {
        display: none;
        height: 0px;
      }
      @include media('sm') {
        display: none;
        height: 0px;
      }
      @include media('md') {
        display:block;
        height: 610px;
      }
    }
    &_right{
      height: 100%;
      width: 50%;
      float: left;
      @include media('xs') {
        padding: rem(30px) rem(30px);
      }
      @include media('sm') {
        padding: rem(30px) rem(30px);
      }
      @include media('md') {
        padding: rem(180px) rem(134px);
      }

      text-align: center;
      & h6{
        text-transform: uppercase;
        font-size: rem(14px);
        letter-spacing: rem(0.5px);
      }
      & h2{
        margin-bottom: rem(35px);
        @include media('xs') {
          font-size: rem(30px);
        }
        @include media('sm') {
          font-size: rem(30px);
        }
        @include media('md') {
          font-size: rem(44px);
        }
      }
      & a{
        font-size: rem(20px);
        line-height: rem(49px);
        &::before{
          width: rem(34px);
          min-width: rem(34px);
          height: rem(34px);
          min-height: rem(34px);
          display: inline-flex;
          border-radius: rem(5px);
          background-color: #ff801b;
          color: $color-white;
          margin-right: rem(14px);
          font-family: "FontAwesome";
          align-items: center;
          justify-content: center;
        }
      }//a

      & .phone{
        &::before{
          content: "\f095";
        }
      }//phone
      & .email{
        @include media('xs') {
          font-size: rem(13px);
        }
        @include media('sm') {
          font-size: rem(13px);
        }
        @include media('md') {
          font-size: rem(20px);
        }
        &::before{
          content: "\f0e0";
        }
      }//email
      & button{
        margin-top: rem(42px);
        @include media('xs') {
          min-width: 100%;
        }
        @include media('sm') {
          min-width: 100%;
        }
        @include media('md') {
          min-width: 18.5625rem;
        }
      }
    }
  }
}
