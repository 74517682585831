.large_contact_form{
  width: 100%;
  height: auto;
  min-height: rem(700px);
  @include media('xs') {
      margin-top: rem(0px);
    }
    @include media('sm') {
      margin-top: rem(0px);
    }
    @include media('md') {
      margin-top: rem(-54px);
    }
  position: relative;
  &_wrap{
    width: rem(1245px);
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: rem(1245px);
    }
    margin: 0 auto;
    & .contact_form_inner{
      padding: rem(30px);
      text-align: center;
      padding-left: rem(304px);
      padding-right: rem(304px);
      @include media('xs') {
        padding-left: rem(14px);
        padding-right: rem(14px);
      }
      @include media('sm') {
        padding-left: rem(14px);
        padding-right: rem(14px);
      }
      @include media('md') {
        padding-left: rem(304px);
        padding-right: rem(304px);
      }
      & h5{
        text-transform: uppercase;
        margin-top: rem(36px);
      }
      & h2{
        margin-bottom: rem(13px);
      }
      & p{
        width: rem(629px);
        max-width: 100%;
        margin: 0 auto;
      }
      & a{
        font-size: 22px;
        margin-top: rem(20px);
        margin-bottom: rem(20px);
        color: $color-primary;
        display: block;
      }
      & .email{
        &::before{
          content: "\f0e0";
          font-family: 'FontAwesome';
          margin-right: 15px;
        }
      }
      & .phone{
        &::before{
          content: "\f095";
          font-family: 'FontAwesome';
          margin-right: 15px;
        }
      }
    }
  }
}

.nf-form-cont{
  width: rem(596px);
  max-width: 100%;
  margin: 0 auto;
}

.ninja-forms-field{
  font-size: rem(16px);
  min-height: rem(56px)!important;//<-- overwrite.
  border-radius: rem(29px);
  border: 1px solid #bdbdbd;
  text-align: center;
}

.nf-field-element{
  & textarea{
    padding: rem(20px);
    font-size: rem(16px);
  }
}



#nf-field-9{
  cursor: pointer;
  height: rem(56px);
  border-radius: rem(29px);
  min-width: rem(297px);
  width: flex;
  text-align: center;
  outline: 0;
  font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
  font-size: rem(14px);
  font-weight: 500;
  letter-spacing: rem(2px);
  text-transform: uppercase;
  width: 100%;
  border-width: 1;
  border-color: $color-orange;
  color: $color-orange;
  background-color: $color-transparent;
  transition: all 0.5s ease;
  transition-delay: 0.2;
  &:hover{
    border-width: 0;
    background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
    background-color: $color-orange;
    text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
    color: $color-white;
  }
}

.nf-field-element{
  min-height: rem(56px);
  & select{
    cursor: pointer;
    height: rem(56px)!important;
    border-radius: rem(29px)!important;
    min-width: rem(297px);
    text-align: center;
    text-align-last: center;
    outline: 0;
    font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
    font-size: rem(14px);
    font-weight: 500;
    letter-spacing: rem(2px);
    text-transform: uppercase;
    width: 100%;
    border-width: 1;
    border-color: $color-orange;
    color: $color-orange;
    background-color: $color-transparent;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    &:hover{
      border-width: 0;
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
    & option{
      text-align: center;
      text-align-last: center;
    }
  }
  & input[type=button]{
    width: 100%;
    min-width: 300px;
  }
}

