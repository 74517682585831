/******************
*
*
*  Docs page
*
*
******************/

.news_items_intro {}

.news_items {
  margin: rem(30px) rem(0px);
  & .item {
    margin: rem(20px) rem(0px);
  }
}
