
// taster session bits
.join_us{
  width: 100%;
  height: auto;
  min-height: rem(583px);
  float: left;
  background-color: black;
  background-image: url(images/joinus_bg.jpg);
  background-position: center;
  background-size: cover;
  @include media('xs') {
    padding: 10px;
    padding-top: rem(30px);
  }
  @include media('sm') {
    padding: 10px;
    padding-top: rem(30px);
  }
  @include media('md') {
    padding: 3.75rem;
    padding-top: rem(60px);
  }
  &_inner{
    width: rem(760px);
    height: auto;
    box-shadow: 0 rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    border-radius: rem(13px);
    background-color: rgba(0,0,0,0.95);
    opacity: 0.95;
    margin: 0 auto;
    text-align: center;
    color: $color-white;
    @include media('xs') {
      width: 100%;
      padding: 50px;
    }
    @include media('sm') {
      width: 100%;
      padding: 50px;
    }
    @include media('md') {
      max-width: rem(760px);
      padding: 4.875rem 6.0625rem;
    }
    & h6{
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(0.5px);
    }
    & h2{
      margin-bottom: rem(17px);
      letter-spacing: 0px;
      line-height: 1.1;
      margin-top: 17px;
    }
    & p{
      margin-bottom: rem(27px);
      letter-spacing: 0px;
    }
  }
}
