// CSS added via customizer

@media (min-width: 768px){
.location_details {
    width: calc(100% - 34.5625rem);
}
}

.location {
	min-height:0;
}
.location:after {
	content:'';
	clear:both;
}

.blog_posts_wrap {
	display:flex;flex-wrap:wrap;
	justify-content:space-between;
}
.blog_archive .blog_archive_top {padding-top:50%;height:0;}
.blog_archive {height:auto;float:none!important;}
.blog_archive
.blog_archive_bottom h3{margin-top:0;}


@media (min-width: 768px){
	.blog_posts_wrap{
	  width:80%;
	}
	.blog_archive{width:48%;margin-left:0;margin-right:0;max-width:none;}
	.pre_blog_posts_wrap {
    width: 90%;
}
}

.sidebar{height:auto;}
.container:after {content:'';clear:both;}




/*BILLING PAGE TIDY UP */

.page-id-325 .mm-textField {
	margin:0 auto;
}

.page-id-325 #mm-billing-information-section .state select {
    width: 96%;
	font-size:1rem;
	color:#666;
}

.page-id-325 #mm-billing-information-section .country select {
	width:96%;
	font-size:1rem;
	color:#666;
}

.page-id-325 #mm_field_cc_exp_month {
	font-size:1rem;
	color:#666;
}

.page-id-325 #mm_field_cc_exp_year {
	font-size:1rem;
	color:#666;
}

.page-id-17509 h4 {
	    font-size: 1.4rem;
	color:#000;
    font-weight: 600;
    margin-top: 1.6rem;
    margin-bottom: 1.0625rem;
}
.page-id-17509 ul {
	margin-bottom:30px!important;
}

.page-id-17521 h4 {
	    font-size: 1.4rem;
	color:#000;
    font-weight: 600;
    margin-top: 1.6rem;
    margin-bottom: 1.0625rem;
}

.page-id-17521 ul {
	margin-bottom:30px!important;
}

.single-post .article-body a {
	color:#FF801B;
	font-weight:bold;
	text-decoration:underline;
}


.button-new {
    border-color: #FF801B;
    color: #FF801B;
    background-color: rgba(255,255,255,0);
    transition: all 0.5s ease;
    border: 2px solid;
    text-transform: uppercase;
    font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .125rem;
    text-transform: uppercase;
    line-height: 1.3;
    min-width: 11.4375rem;
    padding-left: 15px;
    padding-right: 15px;
    }

.footer_contain_subscribe {
    max-width: 600px;
    margin: auto;

    #sib-container {
        background-color: transparent;
    }
    .sib-form {
        padding: 0;
        background-color: transparent;
        font-family: 'Varela Round', sans-serif;
        font-weight: 400;

        .sib-form-message-panel {
            margin: 0;
            color: #fff;
        }
        .sib-form-message-panel__text {
            justify-content: center;
        }
        p, label {
            text-align: center;
            font-weight: 400;
            color: #6b6b6b;
        }
        h2 {
            text-align: center;
            color: #fff;
        }
        button {
            border-radius: 1.8125rem;
            margin-left: auto;
            margin-right: auto;

        }
    }
}
.footer_contain_subscribe_form .the-button input {
   background-color: #000!important;
}

.mc_embed_signup {
	width: 100%;
    margin: 0 auto;
}

#mc_embed_signup div.mce_inline_error {
    margin: 0 0 1em 0;
    padding: 5px 10px;
    background-color: #000!important;
    font-weight: bold;
    z-index: 1;
	font-family: 'Varela Round', sans-serif!important;
	font-size:0.8rem;
	color: #666!important;
}

#mce-EMAIL {
	margin-bottom: 15px!important;
}
#mce-success-response {
	margin: 0 0 1em 0;
    padding: 5px 10px;
    background-color: #000!important;
    font-weight: bold;
    z-index: 1;
	font-family: 'Varela Round', sans-serif!important;
	font-size:0.8rem;
	color: #666!important;
}

.TandC_information {
    width: 100%!important;
}
.thecheckbox {
	float:left!important;
	width:5%;
}
.iagree {
	margin-top: 46px;
}
.btn-center {
	text-align:center;
}
#mm-account-information-section .intro {
    width: 100%;
    text-align: center!important;
    margin: 0 auto;
    letter-spacing: 0.2px;
    transform: translateX(0px)!important;
    margin-bottom: 39px;
}
.page_heading_container{
	position:relative;
}
.page_heading_container::before {
	height:auto!important;
}



.waitlist-header {
    font-size: 2.125rem;
	text-align:center;
	margin:20px 0px 10px 0px;
    font-weight: 700;
    line-height: 2.5rem;
    font-family: "Gotham Rounded Book", 'Varela Round', sans-serif;
}


.menu-model-inner {
    padding-top: 40px!important;
}
