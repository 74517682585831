.page_heading_container{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  @include media('xs') {
    padding-bottom: 50px;
  }
  @include media('sm') {
    padding-bottom: 50px;
  }
  @include media('md') {
    padding-bottom: 90px;
  }
  &_inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9;
    & h5{
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(3.6px);
      font-weight: 600;
      color: $color-orange;
    }
    & h1{
      text-transform: capitalize;
      color: $color-white;
      @include media('xs') {
        margin-top: 0.0625rem;
        font-size: 2.3125rem!important;
      }
      @include media('sm') {
        margin-top: 0.0625rem;
        font-size: 2.3125rem;
      }
      @include media('md') {
        margin-top: rem(17px);
        font-size: rem(53px);
      }
    }
  }
  &::before{
    content: " ";
    width: 100%;
    @include media('xs') {
      height: 251px;
    }
    @include media('sm') {
      height: 251px;
    }
    @include media('md') {
      height: 347px;
    }
    position: absolute;
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.blog_heading_container{
  width: 100%;
  height: rem(145px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  &::before{
    content: " ";
    width: 100%;
    height: rem(145px);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
    max-height: 275px;
  }
}


.page_heading_container {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  @include media('xs') {
    padding-top: 130px;
  }
  @include media('sm') {
    padding-top: 130px;
  }
  @include media('md') {
    padding-top: 170px;
  }
}

.page_heading_image {
  min-width: 96%;
  margin: rem(30px);
  @include media('xs') {
    max-height: 150px;
  }
  @include media('sm') {
    max-height: 150px;
  }
  @include media('md') {
    max-height: 26.1875rem;
  }
  object-fit: cover;
  border-radius: rem(20px);
}


/*
.page_heading_container{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  @include media('xs') {
    height: 20.1875rem;
    padding-top: 17.6875rem!important;
    padding-bottom: 50px;
  }
  @include media('sm') {
    height: 20.1875rem;
    padding-top: 17.6875rem;
    padding-bottom: 50px;
  }
  @include media('md') {
    height: rem(563px);
    padding-top: rem(235px);
    padding-bottom: 0px;
  }
  &_inner{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    @include media('xs') {
      transform: translate(0px, -145px);
    }
    @include media('sm') {
      transform: translate(0px, -145px);
    }
    @include media('md') {
      transform: translate(0px, 0px);
    }
    & h5{
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(3.6px);
      font-weight: 600;
      color: $color-orange;
    }
    & h1{
      text-transform: capitalize;
      color: $color-white;
      @include media('xs') {
        margin-top: 0.0625rem;
        font-size: 2.3125rem!important;
      }
      @include media('sm') {
        margin-top: 0.0625rem;
        font-size: 2.3125rem;
      }
      @include media('md') {
        margin-top: rem(17px);
        font-size: rem(53px);
      }
    }
  }
  &::before{
    content: " ";
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.blog_heading_container{
  width: 100%;
  height: rem(145px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  &::before{
    content: " ";
    width: 100%;
    height: rem(145px);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
    max-height: 275px;
  }
}


.page_heading_container {
  width: 100%;
  height: auto;
  padding-top: 17.6875rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  display: inline-block;
  position: relative;
  @include media('xs') {
  	max-height: 150px;
  }
  @include media('sm') {
  	max-height: 150px;
  }
  @include media('md') {
    max-height: 26.1875rem;
  }
}

.page_heading_image {
  min-width: 96%;
  margin: rem(30px);
  @include media('xs') {
    max-height: 150px;
  }
  @include media('sm') {
    max-height: 150px;
  }
  @include media('md') {
    max-height: 26.1875rem;
  }
  object-fit: cover;
  border-radius: rem(20px);
}

//content header images//

.page_heading_image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.page_heading_image_over {
  background-image: url(./images/blue_bird.svg), url("./images/content_clouds.svg");
  background-repeat: no-repeat;
  background-position: 18% 10%, -18% -35%;
  background-size: rem(66px), rem(250px);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
*/