.song{
  width: 100%;
  &_inner{
    @include media('xs') {
      width: 100%;
      padding: 0px;
      min-height: 210px;
    }
    @include media('sm') {
      width: 100%;
      padding: 0px;
      min-height: 210px;
    }
    @include media('md') {
      width: 1066px;
      padding: 27px;
      min-height: 150px;
    }
    margin: 0 auto;
    margin-bottom: 64px;
    transform: translateY(-20px);
    & h2{
      text-align: center;
      color: #272727;
      font-size: 29px;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: 0px;
      @include media('xs') {
        font-size: 19px;
      }
      @include media('sm') {
        font-size: 19px;
      }
      @include media('md') {
        font-size: 29px;
      }
    }
    & h5{
      text-align: center;
      color: #aaaaaa;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    & .accordion_single{
      @include media('xs') {
        min-height: 210px;
      }
      @include media('sm') {
        min-height: 210px;
      }
      @include media('md') {
        min-height: 150px;
      }
    }
  }
}

.download{
  @include media('xs') {
    min-height: 245px;
  }
  @include media('sm') {
    min-height: 245px;
  }
  @include media('md') {
    min-height: 142px;
  }
  padding: 35px;
  &_icon{
    float: left;
    min-width: 68px;
    min-height: 68px;
    border-radius: 7px;
    border: 1px solid #ff801b;
    margin-right: 30px;

    &::after{
      content: "\f016";
      text-align: center;
      font-size: 31px;
      padding-top: 18px;
      color: #ff801b;
      font-family: "FontAwesome";
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_title{
    height: 100%;
    width: 57%;
    float: left;
    & h2{
      text-align: left;
    }
    & h4{
      text-align: left;
    }
  }
  &_right{
    height: 100%;
    float: left;
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: 25%;
    }
    & button{
      display: inline-table;
      @include media('xs') {
        width: 100%;
        margin: 0 auto;
        margin-top: 15px;
      }
      @include media('sm') {
        width: 100%;
        margin: 0 auto;
        margin-top: 15px;
      }
      @include media('md') {
        width: 229px;
        margin-right: -30px;
        margin-top: 18px;
      }
    }
  }
}

.song-back-button{
  @include media('xs') {
    display: none;
  }
  @include media('sm') {
   display: none;
  }
  @include media('md') {
   display: block;
  }
}


/**
 * Tabs:
 */

 /* TABS */
 .accordion-tabs {
 	*zoom: 1;
 	min-width: 100%;
 	border: 1px solid #dddddd;
 	margin-bottom: 1.5em;
 	margin-left:0;
 	padding-left:0;
 }
     .accordion-tabs:before,
 	.accordion-tabs:after {
 		content: " ";
 		display: table;
 	}
     .accordion-tabs:after {
 		clear: both;
 	}
  .accordion-tabs li.tab-head-cont a{
    //min-width: 166px;
    text-align: center;
    font-family: "Gotham Rounded Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-transform: uppercase;
    @include media('xs') {
      color: #272727;
    }
    @include media('sm') {
      color: #272727;
    }
    @include media('md') {
      color: #ffffff;
    }
  }

 		.accordion-tabs li{
 			list-style:none;
      //min-width: 166px;
 		}

    .accordion-tabs li.tab-head-cont{
      & .is-active{
        &::after{
          content: "";
        }
      }
      @include media('xs') {
        max-width: 100%;
      }
      @include media('sm') {
        max-width: 100%;
      }
      @include media('md') {
        max-width: 20%;
      }
    }

 			.accordion-tabs li.tab-head-cont:first-child a {

 				border-top: 0;
      }
       
 			.accordion-tabs li.tab-head-cont a {
 				text-decoration:none;
 				border-top: 1px solid #dddddd;
 				display: block;
        min-width: 19%;
 				padding: 14px 0px;
        text-transform: uppercase;
        text-align: center;
        font-family: "Gotham Rounded Medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        @include media('xs') {
          color: #272727;
        }
        @include media('sm') {
          color: #272727;
        }
        @include media('md') {
          color: #ffffff;
        }
 			}
 			.accordion-tabs li.tab-head-cont a:hover {
 				color: $color-orange;
 			}
 			.accordion-tabs li.tab-head-cont a:focus {
 				outline: none;
 			}
 			.accordion-tabs li.tab-head-cont a.is-active {
 				background-color: #f7f7f7;
 				border-bottom: 0;
 				color: $color-orange;
        text-transform: uppercase;
        text-align: center;
        font-family: "Gotham Rounded Medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
 			}
 		.accordion-tabs li.tab-head-cont section {
 			padding-top: 30px;
 			background: $color-white;
 			display: none;
 			overflow: hidden;
 			width: 100%;
 		}

 /* RESPONSIVE */
 @media screen and (min-width: 40em) {
 	.accordion-tabs {
 		border: none;
 		position: relative;
    background-color: #2a2a2a;
    border-radius: 13px;
 	}
 		.accordion-tabs li.tab-head-cont {
 			display: inline;
 		}
 			.accordion-tabs li.tab-head-cont:last-child a {
 				border-bottom-left-radius: 0;
 				border-bottom-right-radius: 0;
 			}
 			.accordion-tabs li.tab-head-cont a {
 				display: inline-block;
 				vertical-align: baseline;
 				zoom: 1;
 				border-top: 0;
 			}
 			.accordion-tabs li.tab-head-cont a.is-active {
 				background-color: $color-white;
        text-align: center;
 			}
      .accordion-tabs li.tab-head-cont:first-child a.is-active {
 				border-radius: 13px 0 0;

 			}
      .accordion-tabs li.tab-head-cont:last-child a.is-active {
 				border-radius: 0 13px 0 0;

 			}
 			.accordion-tabs li.tab-head-cont section {
 				border-bottom-left-radius: 0.1875em;
 				border-bottom-right-radius: 0.1875em;
 				//border: 1px solid #dddddd;
 				float: left;
 				left: 0;
 				//padding: 0.75em 0.809em;
 			}
 				.accordion-tabs li.tab-head-cont section p {
 					-webkit-columns: 2;
 					-moz-columns: 2;
 					columns: 2;
 				}
 }

/**
 * Audio player sass.
 */

$button-colour: #ff801b;
$button-hover-colour: #ffb73a;
$background: #e0e0e0;

.playerOuter {
  display: flex;
  margin: 0 auto;
  margin-bottom: 15px;

  &:first-child{
    margin-top: 15px;
  }
}

.ac-container {
  text-align: center;
  display: inline-block;
  @include media('xs') {
    border: 0px solid white;
    width: 100%!important;
  }
  @include media('sm') {
    border: 0px solid white;
    width: 100%!important;
  }
  @include media('md') {
    border: 1px solid $background;
    width: 80%!important;
    float: left;
    overflow: hidden;
  }
}

.ac-play-container {
  background-color: $background;
  @include media('xs') {
    background-color: rgba(255,255,255,0);
    width: 100%!important;
  }
  @include media('sm') {
    background-color: rgba(255,255,255,0);
    width: 100%!important;
  }
  @include media('md') {
    background-color: $background;
    width: 20%!important;
    float: left;
  }
  button:focus {
    outline: none;
  }

  .ac-play {
    font-family: "FontAwesome";
    margin-top: 15%;
    border-radius: 50%;
    background-color: $button-colour;
    border: none;
    color: #ffffff;
    font-size: 40px;
    padding-left: 15px;
    transition: .25s ease background-color;
  }

  .ac-play:before {
    content: "\f04b";
  }

  .ac-play:hover {
    background-color: $button-hover-colour;
    cursor: pointer;
  }

  .ac-playing:before {
    content: "\f04c" !important;
  }

  .ac-playing {
    padding-top: 4px;
    padding-left: 6px !important;
    font-size: 35px !important;
  }
}

#ac-play-btn1, #ac-play-btn2, #ac-play-btn3, #ac-play-btn4, #ac-play-btn5, #ac-play-btn6{
  min-width: 0px!important;
  width: 70px;
  max-width: 70px;
}
