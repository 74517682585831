
// welcome section:

.welcome_section{
  width: 100%;
  height: auto;
  @include media('xs') {
    min-height: rem(272px);
  }
  @include media('sm') {
    min-height: rem(272px);
  }
  @include media('md') {
    min-height: rem(372px);
  }
  float: left;
  background-color: $color-white;
  &_inner{
    width: rem(1235px);
    max-width: 100%;
    @include media('xs') {
      width: 100%;
      transform: translateY(rem(0px));
    }
    @include media('sm') {
      width: 100%;
      transform: translateY(rem(0px));
    }
    @include media('md') {
      width: rem(1235px);
      transform: translateY(rem(-54px));
      height: rem(447px);
    }
    height: rem(447px);
    @media only screen and (min-width: 768px) and (max-width: 1026px) {
      height: rem(600px);
    }
    margin: 0 auto;
    
    overflow: hidden;
    border-top: 0px;
    border-right: 0px;
    &_left{
      width: 50%;
      height: 100%;
      @include media('xs') {
        width: 100%;
        height: 50%;
      }
      @include media('sm') {
        width: 100%;
        height: 40%;
      }
      @include media('md') {
        width: 50%;
        height: 100%;
      }
      float: left;
    }
    &_right{
      width: 50%;
      height: 100%;
      @include media('xs') {
        display: none;
      }
      @include media('sm') {
        display: none;
      }
      @include media('md') {
        display: block;
        background-position: cover;
      }
      float: left;
      background-image:url('images/video.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.welcome_inner{
  text-align: center;
  @include media('xs'){
    padding-top: 5.25rem;
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  @include media('sm'){
    padding-top: rem(20px);
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  @include media('md'){
    padding-top: rem(91px);
    padding-left: rem(69px);
    padding-right: rem(69px);//was 18px.
  }
  & h6{
    text-transform: uppercase;
    font-size: rem(14px);
    color: $color-grey--light;
    @include media('xs') {
      font-size: rem(12px);
    }
    @include media('sm') {
      font-size: rem(12px);
    }
    @include media('md') {
      font-size: rem(14px);
    }
  }
  & h2{
    margin-bottom: rem(16.5px);
    color: $color-black;
    @include media('xs') {
      font-size: 2.3rem;
      margin-top: 10px;
      letter-spacing: 0px;
      line-height: 39px;
    }
    @include media('sm') {
      font-size: 2.3rem;
      margin-top: 10px;
      letter-spacing: 0px;
      line-height: 39px;
    }
    @include media('md') {
      font-size: 2.375rem;
      margin-top: 20px;
    }
  }
  & p{
    font-size: rem(16px);
    color: $color-grey--mid;
    margin-bottom: rem(22px);
  }
}
