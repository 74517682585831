/* CSS Document */
/*!
Theme Name: Ketchup
Description:
Version: 3.0
Author: Alex Woollam
Author URI: http://www.authenticstyle.co.uk/
Tags: starter theme, light, sass, gulp, livereload
*/
@import "bootstrap/bootstrap.min";

/* Vendor */
@import "susy";
@import "su";
@import "bulma";

/* Setup */
@import "reset";
@import "mixins";
@import "variables";
@import "ifs";
@import "fonts";
@import "globals";


/* WP parts */
@import "popup";
@import "header";
@import "home_page_slider";
@import "menu";
@import "buttons";
@import "homepage";
@import "sidebar";
@import "news-page";
@import "docs-page";
@import "contact-page";
@import "gallery-page";
@import "quicklinks";
@import "alerts";
@import "footer";


/* weird apge styles */
@import "blog";

/* sidebar blocks */
@import "sidebar-blocks/join_for_tasting";
@import "sidebar-blocks/location";

/* modals */
@import "modals/book_taster";

/* Theme blocks */
@import "blocks/welcome_section";
@import "blocks/taster_sess";
@import "blocks/reasons";
@import "blocks/page_header_and_image";
@import "blocks/location_slider";
@import "blocks/locations";
@import "blocks/join_for_tasting";
@import "blocks/contact_us";
@import "blocks/blog_and_social";
@import "blocks/our_beliefs";
@import "blocks/testimonial";
@import "blocks/faq";
@import "blocks/headsup";
@import "blocks/sign_up";
@import "forms/large_contact_form";
@import "forms/waiting_list";

@import "blocks/members/members_header_and_image";
@import "blocks/members/calendar";
@import "blocks/members/dash";
@import "blocks/members/guides";
@import "blocks/members/profile";
@import "blocks/members/songs";
@import "blocks/members/song";
@import "blocks/members/mm-login";

@import "overrides";

@import "additional";
