/******************
*
*
*  Buttons and form fields
*
*
******************/

button {
  border-style: solid;
  cursor: pointer;
  min-height: rem(56px);
  border-radius: rem(29px);
  min-width: rem(297px);
  @include media('xs') {
    min-width: 12.5625rem;
  }
  @include media('sm') {
    min-width: 12.5625rem;
  }
  @include media('md') {
    min-width: rem(297px);
  }
  max-width: 100%;
  text-align: center;
  outline: 0;
  font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
  font-size: rem(14px);
  font-weight: 500;
  letter-spacing: rem(2px);
  text-transform: uppercase;
  &:focus{
    outline: 0px;
  }
  line-height: 1.3;
}

.btn{
  padding-top: 5px;
  cursor: pointer;
  max-width: 100%;
  @include media('xs') {
    padding-left: 18px;
    padding-right: 18px;
  }
  @include media('sm') {
    padding-left: 18px;
    padding-right: 18px;
  }
  @include media('md') {
    padding-left: 15px;
    padding-right: 15px;
  }
  &_orange{
    border-width: 1;
    border-color: $color-orange;
    color: $color-orange;
    background-color: $color-transparent;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    @include media('xs') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('sm') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('md') {
      padding-left: 15px;
      padding-right: 15px;
    }
    &:hover{
      border-width: 0;
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
  }
  &_white{
    border-width: 1;
    border-color: $color-white;
    color: $color-white;
    background-color: $color-transparent;
    text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    max-width: 100%;
    transition-delay: 0;
    @include media('xs') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('sm') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('md') {
      padding-left: 15px;
      padding-right: 15px;
    }
    &:hover{
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      border-width: 1;
      border-color: $color-white;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
  }
  &_filled{
    border-width: 0;
    background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
    background-color: $color-orange;
    text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
    color: $color-white;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    @include media('xs') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('sm') {
      padding-left: 18px;
      padding-right: 18px;
    }
    @include media('md') {
      padding-left: 15px;
      padding-right: 15px;
    }
    &:hover {
      background-image: none;
      background-color: $transparent;
      border-width: rem(1px);
    }
    &:active {
      background-image: none;
      background-color: $transparent;
      border-width: rem(1px);
    }
  }//alt
  &_filled_white{
    border-width: 0;
    background-color: $color-white;
    color: $color-orange;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    &:hover {
      background-image: none;
      background-color: $transparent;
      border-width: rem(1px);
      border-color: white;
      color: $color-white;
    }
    &:active {
      background-image: none;
      background-color: $transparent;
      border-width: rem(1px);
      color: $color-white;
    }
  }//alt-white
  &_active{
    border-width: rem(0px);
    background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
    background-color: $color-orange;
    text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
    color: $color-white;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    z-index:2;
    &::after{
      content: "";
      position: relative;
      display: block;
      left: 45%;
      max-width: 40px;
      bottom: rem(-24px);
      margin-left: rem(-10px);
      margin-top: rem(-24px);
      transform: translateY(rem(10px));
      border-left: rem(20px) solid transparent;
      border-right: rem(20px) solid transparent;
      border-top: rem(23px) solid #ffa02d;
      z-index:1;
    }
    &:focus{
      outline: 0px;
    }
  }//active
  &_play{
    min-width: rem(71px);
    width: rem(71px);
    height: rem(71px);
    background-color: $color-orange;
  }//play
  &_extrasmall{
    min-width: rem(0px)!important;//overwrite default.
    min-height: rem(50px)!important;//overwrite default.
    padding-left: rem(20px);
    padding-right: rem(20px);
    border-width: rem(1px);
    border-color: $color-orange;
    color: $color-orange;
    background-color: $color-white;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    padding-top: 5px;
    &:hover{
      border-width: rem(0px);
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
  }
  &_close{
    min-width: 48px;
    max-width: 48px;
    width: 48px;
    right: 22%;
    top: 44px;
    min-height: 48px;
    max-height: 48px;
    height: 48px;
    background-color: #FF801B;
    border: 0px white solid;
    position: absolute;
    margin-top: -80px;
    margin-right: -25%;
    
    &::after{
      content: "x";
      color: $color-white;
    }
  }
  &_back{
    margin: 0 auto;
    margin-top: 30px;
    display: block;
    margin-bottom: 30px;
    max-width: 100px;
    min-width: 228px;
    height: 44px;
  }
  &_next{
    border-radius: 10px;
    width: 45px;
    max-width: 46px;
    min-width: 46px;
    font-size: 35px;
    height: 47px;
    transform: translate(-3px, 10px);
    &:after{
      content: "\f105";
      font-family: "FontAwesome";
    }
  }
  &_prev{
    border-radius: 10px;
    width: 45px;
    max-width: 46px;
    min-width: 46px;
    font-size: 35px;
    height: 47px;
    transform: translate(-3px, 10px);
    &:after{
      content: "\f104";
      font-family: "FontAwesome";
    }
  }
}//btn

.btn_members{
  &_white{
    padding-left: 20px;
    padding-right: 20px;
    min-height: rem(44px)!important;
    min-width: rem(160px)!important;
    border-width: 1;
    border-color: $color-white;
    color: $color-white;
    background-color: $color-transparent;
    transition: all 0.5s ease;
    transition-delay: 0.2;
    &:hover{
      border-width: 0;
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
    &_active{
      min-height: 45px;
      min-width: rem(160px)!important;
      width: flex;
      border-width: 0;
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
  }
}

//Accordion specific:

.accordion-header{
  min-width: 100%;
  height: rem(80px);
  border-color: $color-transparent;
  border-width: rem(0px);
  border-style: none;
  border-radius: rem(0px);
  padding-left: rem(0px);
  padding-right: rem(0px);
  @include media('xs') {
    background-color: white;
  }
  @include media('sm') {
    background-color: white;
  }
  @include media('md') {
    background-color: white;
  }
}

// Dropdown input:
.dropdown{
  margin: 0 auto;
  display: block;
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 588px;
  }
  height: 63px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.22);
  border-radius: 29px;
  background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
  border-width: 0px;
  text-align: center;
  transform: translate(0px, -30px);
  & option{
    text-align: center;
    text-align-last: center;
  }
  & select{
    text-align: center;
    text-align-last: center;
    margin: auto;
    height: 63px;
    width: 94%;
    border-radius: 20px;
    background-color: rgba(0,0,0,0);
    border-color: rgba(0,0,0,0);
    font-size: 20px;
    color: rgba(255,255,255,1);
    &:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
    & option{
      text-align: center;
    }
  }
}

//Soo important.. grim.
.mm-button{
  cursor: pointer;
  text-decoration: none!important;
  padding-top: 20px!important;
  height: rem(56px)!important;
  border-radius: rem(29px)!important;
  min-width: rem(297px)!important;
  width: flex!important;
  text-align: center!important;
  outline: 0!important;
  font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif!important;
  font-size: rem(14px)!important;
  font-weight: 500!important;
  letter-spacing: rem(2px)!important;
  text-transform: uppercase!important;
  border-width: 1!important;
  border-color: $color-orange!important;
  color: $color-orange!important;
  background: $color-transparent!important;
  transition: all 0.5s ease!important;
  transition-delay: 0.2!important;
  &:hover{
    border-width: 0!important;
    background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%)!important;
    background-color: $color-orange!important;
    text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5)!important;
    color: $color-white!important;
  }
}
