.our_beliefs{
  width: rem(1243px);
  @include media('xs') {
    width: 100%;
    margin-top: rem(30px);
  }
  @include media('sm') {
    width: 100%;
    margin-top: rem(30px);

  }
  @include media('xlarge') {
    width: rem(1243px);
    margin-top: rem(100px);

  }
  height: auto;
  margin: 0 auto;
  margin-bottom: rem(107px);
  &_top{
    width: 100%;
    padding: 30px;
    text-align: center;
    & h5{
      text-transform: uppercase;
      margin-bottom: rem(11px);
    }
    & h3{
      font-size: rem(40px);
      font-weight: 600;
      margin-bottom: rem(19px);
    }
    & p{
      margin-bottom: rem(42px);
    }
  }
  &_bottom{
    height: auto;
    min-height: rem(756px);
    margin: 0 auto;
     @include media('xs') {
        width: 100%;
      }
      @include media('sm') {
        width: 100%;
      }
      @include media('md') {
        width: 1243px;
      }
    &_row{
      margin-bottom: rem(23px);
      float: left;
      height: 50%;
      @include media('xs') {
        height: auto;
        width: 100%;
      }
      @include media('sm') {
        height: auto;
        width: 100%;
      }
      @include media('md') {
        height: 50%;
        width: 1243px;
      }
      & .belief{
        float: left;
        width: rem(399px);
        min-height: 28.75rem;
        height: auto;
        margin-bottom: rem(23px);
        text-align: center;
        padding: rem(48px);
        @include media('xs') {
          width: 100%;
          margin-left: rem(0px);
        }
        @include media('sm') {
          width: 100%;
          margin-left: rem(0px);
        }
        @include media('md') {
          width: rem(399px);
          margin-left: rem(23px);
        }
        &:first-child{
          margin-left: rem(0px);
        }
        &:nth-of-type(3n+1){
          margin-left: rem(0px);
        }
        & .icon{
          background-color: $color-orange;
          width: 68px;
          text-align: center;
          height: 68px;
          padding: 11px;
          font-size: 22px;
          border-radius: 6px;
          margin-bottom: 30px;
          background-position: center;
          background-size: contain;
        }
        & h4{
          font-size: rem(31px);
          color: #000000;
          font-weight: 700;
        }
      }
    }
  }
}
