.reasons{
  width: 100%;
  height: auto;
  min-height: rem(716px);
  float: left;
  background-color: $color-black;
  background-image:url('images/reasons_bg.png');
  background-position: center;
  background-size: cover;
  &_inner{
    width: $site-width;
    @include media('xs') {
		  width: 100%;
	  }
    @include media('sm') {
		  width: 100%;
	  }
    @include media('xlarge') {
		  width: $site-width;
	  }
    height: 100%;
    margin: 0 auto;
    padding: rem(0px) rem(45px);
    &_top{
      width: 100%;
      height: 50%;
      
    @include media('xs') {
      margin-right: 0px;
      margin-left: 0px;
    }
    @include media('sm') {
      margin-right: 0px;
      margin-left: 0px;
    }
    @include media('md') {
      margin-right: -15px;
      margin-left: -15px;
    }
      &_block{
        padding: rem(30px);
        color: $color-white;
        background-repeat: no-repeat;
        background-position: 86% 60%;
        @include media('xs') {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: rem(30px);
    	  }
        @include media('sm') {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: rem(30px);
    	  }
        @include media('md') {
    		  padding-left: 0px;
          padding-right: 30px;
          padding-top: rem(105px);
    	  }
        & h6{
          text-transform: uppercase;
          font-size: rem(13px);
          letter-spacing: rem(1.0px);
          margin-left: rem(5px);
        }
        & h3{
          color: $color-white;
        }
        & h2{
          line-height: 1.2;
          margin-top: 2.8rem;
          margin-bottom: 2.8rem;
          letter-spacing: 0px;
          font-size: 36px;
        }
        & hr{
          width: rem(62px);
          background-color: $color-grey--mid;
        }//hr
        & .reason_icon{
          width: rem(74px);
          height: rem(74px);
          border-radius: rem(10px);
          margin-bottom: rem(26px);
          background: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
          &_auditions{
            background-image: url(images/no-audition.png), linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
            background-repeat: no-repeat;
            background-position: center;
          }
          &_sheet-music{
            background-image: url(images/no-sheet-music.png), linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &:nth-child(2){
          background-image: url('images/1.png');
        }
        &:nth-child(3){
          background-image: url('images/2.png');
        }
      }//block
    }//top
    &_bottom{
      width: 100%;
      height: 50%;
      &_block{
        padding: rem(30px);
        padding-top: 9px;
        color: $color-white;
        background-repeat: no-repeat;
        background-position: 86% 60%;
        @include media('xs') {
    		  padding-left: 15px;
    	  }
        @include media('sm') {
    		  padding-left: 0px;
    	  }
        @include media('md') {
    		  padding-left: 0px;
        }
        & h3{
          color: $color-white;
        }
        & .reason_icon{
          width: rem(74px);
          height: rem(74px);
          border-radius: rem(10px);
          margin-bottom: rem(26px);
          background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
          &_social-events{
            background-image: url(images/social-events.png), linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
            background-repeat: no-repeat;
            background-position: center;
          }
          &_repertoire{
            background-image: url(images/pop-rep.png), linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
            background-repeat: no-repeat;
            background-position: center;
          }
          &_rehersals{
            background-image: url(images/rehersals.png), linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &:nth-child(1){
          background-image: url('images/3.png');
        }
        &:nth-child(2){
          background-image: url('images/4.png');
        }
        &:nth-child(3){
          background-image: url('images/5.png');
        }
      }
    }//bottom
  }
}

.block_3{
  height: 100%;
  float: left;
  display: block;
  & h3{
    font-size: rem(25px);
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 27px;
    letter-spacing: 2px;
  }
  & p{
    color: $color-white;
    margin-top: 18px;
    letter-spacing: -0.1px;
  }


}//block_3
