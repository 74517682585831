.join_taster_block{
  height: auto;
  &_inner{
    width: rem(1228px);
    @include media('xs') {
      display: inline-block;
      margin: 0px auto;
      width: 100%;
    }
    @include media('sm') {
      display: inline-block;
      margin: 0px auto;
      width: 100%;
    }
    @include media('md') {
      display: block;
      margin: 50px auto;
      width: rem(1228px);
      max-width: 100%;
    }
    height: auto;
    min-height: rem(291px);
    margin: 0 auto;
    margin-top: 90px;
    text-align: center;
    color: $color-white;
    padding: rem(49px);
    & h5{
      @include media('xs') {
        margin-bottom: rem(10px);
      }
      @include media('sm') {
        margin-bottom: rem(10px);
      }
      @include media('md') {
        margin-bottom: rem(3px);
      }
      text-transform: uppercase;
    }
    & h2{
      margin-bottom: rem(11px);
      @include media('xs') {
        line-height: 1.0;
      }
      @include media('sm') {
        line-height: 1.0;
      }
      @include media('md') {
        line-height: 1.4;
      }
    }
    & p{
      color: $color-white;
    }
    & button{
       @include media('xs') {
        min-width: 100%!important;
        max-width: 100%!important;
      }
      @include media('sm') {
        min-width: 100%!important;
        max-width: 100%!important;
      }
      @include media('md') {
        min-width: 300px!important;
        max-width: 300px!important;
      }
      
    }
  }
}
