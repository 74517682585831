
.loginform{
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
}

.members_dash{
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 55px;
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
  height: auto;
  padding-top: 30px;
  padding: 30px;
  z-index: 9;
  position: relative;
}

.members_video{
  width: 100%;
  height: 515px;
  overflow: hidden;
  background-color: black;
  border-radius: 7px;
}


.members_faq{
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 0px;
  width: 1066px;
  height: auto;
  min-height: 0;
  padding-top: 0px;
  padding: 0px;
  z-index: 9;
  position: relative;
}
