/******************
*
*
*  Homepage only styling
*
*
******************/

.latest_news_events_row {
  width: $site-width;
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: $site-width;
  }
  background-color: $news-and-events-bg-colour;
  height: $news-and-events-height;
}


.news_events_home_block {
  & h1 {
    text-align: center;
    font-size: rem(30px);
    margin-bottom: rem(20px);
  }
  & a {
    text-align: center;
    margin: 0 auto;
  }
}

.view_all {
  width: 100%;
  text-align: center;
  & a {
    font-size: $font-size-for-viewall;
  }
}


.home_events {
  width: calc(#{$site-width}/2);
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: $site-width;
  }
  float: $which_side_for_events;
  height: 100%;
  background-color: $events-bg-colour;
  padding: rem(40px);
  & h1 {
    text-align: center;
    font-size: rem(30px);
    margin-bottom: rem(20px);
  }
  & a {
    text-align: center;
    margin: 0 auto;
  }
}

.home_news {
  width: calc(#{$site-width}/2);
  float: left;
  height: 100%;
  background-color: $news-bg-colour;
  padding: rem(40px);
}



.home_news_date {
  color: $home_news_date_colour;
  text-align: $home_news_date_align;
  font-size: $home_news_date_font_size;
  margin-top: rem(6px);
  margin-bottom: rem(6px);
}

.event_date {
  text-align: center;
}



.home_news_single {
  padding: rem(5px);
  margin: rem(10px);
  text-align: center;
  & .event_image {
    font-family: FontAwesome;
    color: $color-green--mid;
    font-size: rem(70px);
    text-align: center;
    margin-top: rem(20px);
    margin-bottom: rem(20px);
  }
  & h2 {
    margin-top: rem(20px);
    color: $home_news_title_colour;
    text-align: $home_news_title_align;
    font-size: $home_news_title_font_size;
    margin-bottom: rem(30px);
  }
  & a {
    h2 {
      margin-top: rem(20px);
      color: $home_news_title_colour;
      text-align: $home_news_title_align;
      font-size: $home_news_title_font_size;
      margin-bottom: rem(30px);
    }
    & p {
      color: $home_news_content_colour;
      text-align: $home_news_content_align;
      font-size: $home_news_content_font_size;
    }
  }
  & .read_more {
    text-align: $home_news_read_align;
    & a {
      color: $home_news_read_colour;
      font-size: $home_news_read_font_size;
    }
  }
}

.homenewsimg {
  margin: 0 auto;
  width: rem(277px);
  height: rem(174px);
  border-radius: rem(20px);
  background-size: cover;
  position: relative;
}

.homenewsimg_blank {
  margin: 0 auto;
  width: rem(277px);
  height: rem(174px);
  border-radius: rem(20px);
  background-image: url($logo);
  background-color: $color-navy--light;
  background-size: rem(150px);
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.news_date_container {
  background-color: $color-green--dark;
  width: 60%;
  padding: rem(5px);
  opacity: 0.9;
  bottom: 0;
  border-bottom-left-radius: rem(20px);
  border-top-right-radius: rem(10px);
  position: absolute;
}

/* new bits of homepage */

.social_preview_inner{
  padding: 38px;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 31px;
  &_top_left{
    float: left;
    width: 151px;
    margin-bottom: 20px;
    & p{
      color: #141414;
      font-size: 16px;
      font-weight: 300;
      line-height: 10px;
      letter-spacing: 0.4px;
      margin-bottom: 12.4px;
    }
    & h3{
      font-size: 22px;
      font-weight: 700;
    }
  }
  &_top_right{
    float: left;
    width: 46px;
    height: 46px;
    border-radius: 8px;
    background-color: $color-orange;
    transform: translate(21px, -4px);
    &::before{
      content: '\f09a';
      font-family: "FontAwesome";
      color: $color-white;
      margin-top: 7px;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_text{
    min-height: 180px;
    max-height: 180px;
    margin-top: 3px;
  }
  & button{
    margin-top: 7px;
  }
}


#google-image-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  @include media('md') {
    height: 100%;
  }
}