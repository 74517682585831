/******************
*
*
*  Contact page
*
*
******************/

.news_items_intro {}

.contact-row1 {
  width: 100%;
  display: inline-block;
}

.contact_left {
  width: 50%;
  float: left;
}

.contact_right {
  width: 50%;
  float: left;
  text-align: right;
}

.contact-row2 {
  width: 100%;
}

.nf-form-fields-required{
  display: none;
}

.nf-error-msg{
  font-family: "Gotham Rounded Medium";
}

.nf-field-label{
  font-family: "Gotham Rounded Medium"!important;
}

.nf-field-element{
  & p{
    font-size: 30px;
  }
  & select{
    border-radius: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
