.locations{
  width: 100%;
  height: auto;
  min-height: rem(875px);
  display: inline-block;
  float: left;
  background-color: white;
  &_inner{
  width: $site-width;
  height: auto;
  overflow:hidden;
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: $site-width;
  }
  margin: 0 auto;
    &_top{
      width: 100%;
      
      @include media('xs') {
        height: auto;
      }
      @include media('sm') {
        height: auto;
      }
      @include media('md') {
        height: rem(200px);
      }
      text-align: center;
      padding-top: rem(81px);
      & h6{
        text-align: center;
        text-transform: uppercase;
        font-size: rem(14px);
        letter-spacing: rem(0.5px);
      }
      & h2{
        text-align: center;
        text-transform: capitalize;
        margin-bottom: rem(17px);
        letter-spacing: 0px;
      }
      & p{
        text-align: center;
      }
    }
    &_bottom{
      height: rem(622px);
      @include media('xs') {
        height: auto;
      }
      @include media('sm') {
        height: auto;
      }
      @include media('md') {
        height: rem(622px);
      }
      
      width: 100%;
      background-color: $color-white;
      &_buttons{
        width: rem(948px);
        @include media('xs') {
          width: 320px;
          padding: 50px;
          margin-top: -0.875rem;
        }
        @include media('sm') {
          width: 320px;
          padding: 50px;
          margin-top: -0.875rem;
        }
        @include media('md') {
          width: rem(948px);
          padding: 0px;
          margin-top: rem(34px);
        }
        margin: 0 auto;
        margin-bottom: 30px;
        & button{
          height: rem(44px)!important;//overwrite defaults
          width: rem(225px)!important;//overwrite theme defaults
          min-width: rem(225px)!important;//overwrite theme defaults
          max-width: rem(225px)!important;//overwrite theme defaults
          float: left;
          margin-right: rem(16px);
          &:last-child{
            margin-right: rem(0px);
          }
          @include media('xs') {
            margin: 0 auto;
            margin-bottom: 30px;
          }
          @include media('sm') {
            margin: 0 auto;
            margin-bottom: 30px;
          }
          @include media('md') {
          margin: 0 auto;
          margin-right: rem(16px);
          }
        }
      }
      & .location_focus{
        width: rem(1234px);
        @include media('xs') {
          width: 100%;
        }
        @include media('sm') {
          width: 100%;
        }
        @include media('md') {
          width: rem(1234px);
        }
        margin: 0 auto;
        height: rem(447px);
        margin-top: rem(42px);
        &_left{
          height: 100%;
          width: 44.4%;
          float: left;
          @include media('xs') {
            width: 100%;
          }
          @include media('sm') {
            width: 100%;
          }
          @include media('md') {
            width: 44.4%;
          }
        }
        &_right{
          padding-top: rem(94px);
          padding-left: rem(82px);
          padding-right: rem(18px);
          height: 100%;
          width: 50%;
          float: left;
          text-align: center;
          @include media('xs') {
            width: 100%;
            padding-top: rem(30px);
            padding-left: rem(10px);
            padding-right: rem(10px)!important;
          }
          @include media('sm') {
            width: 100%;
            padding-top: rem(30px);
            padding-left: rem(10px);
            padding-right: rem(10px)!important;
          }
          @include media('md') {
            width: 50%;
            padding-top: rem(94px);
            padding-left: rem(82px);
            padding-right: rem(18px);
          }
          & h6{
            text-transform: uppercase;
            font-size: rem(13px);
            margin-bottom: rem(-3px);
            font-weight: 600;
            letter-spacing: 1px;
          }
          & h2{
            font-weight: 500;
            margin-bottom: rem(20px);
            letter-spacing: 0px;
          }
        }
      }
    }//_bottom
  }
}