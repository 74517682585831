// News and social:

.blog_block{
  width: 100%;
  height: auto;
  min-height: 45.3125rem;
  float: left;
  &_inner{
    height: 100%;
    width: $site-width;
    padding: rem(99px);
    @include media('xs'){
      width: 100%;
      padding: rem(10px);
    }
    @include media('sm'){
      width: 100%;
      padding: rem(10px);
    }
    @include media('md'){
      padding: rem(99px);
      padding-top: 28px;
    }
    @include media('md'){
      width: $site-width;
    }

    margin: 0 auto;

    &_top{
      text-align: center;
      padding-left: rem(364px);
      padding-right: rem(364px);
      @include media('xs'){
        padding-left: rem(20px);
        padding-right: rem(20px);
      }
      @include media('sm'){
        padding-left: rem(20px);
        padding-right: rem(20px);
      }
      @include media('md'){
        padding-left: rem(364px);
        padding-right: rem(364px);
      }
      & h6{
        text-transform: uppercase;
        font-size: rem(14px);
        letter-spacing: rem(1.2px);
        color: $color-grey--light;
      }
      & h2{
        margin-bottom: rem(19px);
        color: $color-black;
        letter-spacing: 0px;
        @include media('xs'){
          line-height: 1.2;
          margin-top: 30px;
        }
        @include media('sm'){
          line-height: 1.2;
          margin-top: 30px;
        }
        @include media('md'){
          line-height: 1.2;
          margin-top: 30px;
        }
      }
      & p{
        margin-bottom: rem(52px);
        letter-spacing: 0px;
      }
    }
    &_bottom{
      width: 100%;
      height: auto;
      min-height: rem(410px);
      @include media('xs'){
        margin: 0 auto;
      }
      @include media('sm'){
        margin: 0 auto;
      }
      @include media('md'){
        margin-left: -15px;
        margin-right: -15px;
        width: 50%;
      }
      & .blog_preview{
        height: 100%;
        width: 50%;
        @include media('xs'){
          width: 100%;
        }
        @include media('sm'){
          width: 100%;
        }
        @include media('md'){
          width: 50%;
        }
        float: left;
        &_inner{
          width: rem(586px);
          height: 100%;
          float: left;
          text-align: center;
          padding: rem(30px);
          padding-top: 48px;
          @include media('xs'){
            width: 100%;
          }
          @include media('sm'){
            width: 100%;
          }
          @include media('md'){
            width: rem(586px);
          }
          & .icon{
            &::before{
              content: "\f15c";
              color: $color-white;
              font-family: "fontawesome";
            }
            background-color: $color-orange;
            width: 46px;
            text-align: center;
            height: 46px;
            padding: 11px;
            font-size: 22px;
            border-radius: 6px;
            margin-bottom: 25px;
          }
          & h2{
            margin-top: rem(-3px);
            font-size: rem(28px);
            color: #060606;
            font-family: "Gotham Rounded Medium";
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 33px;
          }
          & p{
            margin-top: rem(20px);
          }
        }
      }
      & .social_preview{
        height: 100%;
        width: 50%;
        padding-left: 5px;
        @include media('xs'){
          width: 100%;
        }
        @include media('sm'){
          width: 100%;
        }
        @include media('md'){
          width: 50%;
        }
        float: left;
        &_inner{
          width: rem(287px);
          height: 100%;
          float: left;
          margin-left: rem(42px);
          &:first-child{
            margin-left: rem(0px);
          }

        }
      }//social_preview
    }
  }//_inner
}

// Members area News and social:

.members_blog_block{
  width: 100%;
  height: auto;
  float: left;
  padding-top: 34px;
  &_inner{
    height: 100%;
    width: 100%;
    padding: rem(0px);
    @include media('xs'){
      width: 100%;
      padding: rem(10px);
    }
    @include media('sm'){
      width: 100%;
      padding: rem(10px);
    }
    @include media('md'){
      padding: rem(0px);
    }
    @include media('md'){
      width: 100%;
    }
    margin: 0 auto;
    &_top{
      text-align: center;
      & h6{
        text-transform: uppercase;
        font-size: rem(14px);
        letter-spacing: rem(1.2px);
        color: $color-grey--light;
      }
      & h2{
        margin-bottom: rem(19px);
        font-weight: 500;
      }
      & p{
        margin-bottom: rem(52px);
      }
    }
    &_bottom{
      width: 100%;
      height: rem(410px);
      margin-top: 38px;
      & .members_blog_preview{
        height: auto;
        width: 49%;
        margin-bottom: 3.2%;
        padding-bottom: 0px;
        margin-right: 2%;
        float: left;
        &:nth-child(even){
          margin-right: 0;
        }
        @include media('xs'){
          width: 100%;
        }
        @include media('sm'){
          width: 100%;
        }
        @include media('md'){
          width: 49%;
        }
        &_inner{
          width: 100%;
          height: 100%;
          float: left;
          text-align: center;
          padding: rem(30px);
          padding-top: 49px;
          min-height: 468px;
          & .icon{
            &::before{
              content: "\f15c";
              color: $color-white;
              font-family: "fontawesome";
            }
            background-color: $color-orange;
            width: 46px;
            text-align: center;
            height: 46px;
            padding: 11px;
            font-size: 22px;
            border-radius: 6px;
            margin-bottom: 30px;
          }
          & h2{
            margin-top: rem(-7px);
            font-size: rem(26px);
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: 0px!important;
            color: #060606;
          }
          & p{
            margin-top: rem(20px);
          }
        }
      }
      & .members_social_preview{
        height: fit-content;
        width: 32.2%;
        margin-right: 17px;
        &:last-child{
          margin-right: 0px;
        }
        & .social_preview_inner_top_right{
          float: right;
          transform: translate(9px, -4px);
        }
        @include media('xs'){
          width: 100%;
        }
        @include media('sm'){
          width: 100%;
        }
        @include media('md'){
          width: 32.2%;
        }
        float: left;
        &_inner{
          padding: 33px;
          padding-top: 42px;
          padding-right: 36px;
          width: 100%;
          height: 100%;
          float: left;
          margin-left: rem(47px);
          &:first-child{
            margin-left: rem(0px);
          }

        }
      }//social_preview
    }
  }//_inner
}
