
.profile_menu{
  & p{
    text-align: center;
    color: $color-grey--mid;
    & a{
      text-transform: capitalize;
      color: $color-orange;
      &:hover{
        color: $color-grey--dark;
      }
    }
  }
}
.profile_card{
  margin: 0 auto;
  width: 773px;
  height: auto;
  padding: 30px;
  border-radius: 13px;
  border: 1px solid #dcdcdc;
  background-color: #f6f6f6;
  min-height: 300px;
  &_left{
    width: 50%;
    height: 100%;
    float: left;
    & .user_img{
      width: 250px;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &_right{
    width: 50%;
    height: 100%;
    float: left;
    text-align: center;
    & h2{
      margin-bottom: 6px;
    }
    & .user_phone{
      color: #6b6b6b;
      font-family: "Open Sans";
      font-size: 22px;
      line-height: 26px;
      &::before{
        content: "\f098";
        font-family: 'FontAwesome';
        color: $color-orange;
        font-size: 34px;
        margin-right: 15px;
        margin-top: 15px;
        transform: translateY(6px);
        position: relative;
        display: inline-block;
      }
    }
    & .user_email{
      color: #ff801b;
      font-family: "Open Sans";
      font-size: 22px;
      line-height: 26px;
      &::before{
        content: "\f199";
        font-family: 'FontAwesome';
        color: $color-orange;
        font-size: 34px;
        margin-right: 15px;
        margin-top: 15px;
        transform: translateY(6px);
        position: relative;
        display: inline-block;
      }
    }
    & .user_address{
      color: #6b6b6b;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 26px;
    }
  }
}

// OVERWRITNG MM:

.mm-myaccount-module{
  margin: 0 auto!important;
  width: 773px;
  height: auto;
  padding: 30px;
  border-radius: 13px;
  border: 1px solid #dcdcdc!important;
  background-color: #f6f6f6;
  min-height: 300px;
  margin: 0 auto;
  margin-bottom: 20px!important;
}

.mm-myaccount-module-header {
    font-size: 2.375rem!important;
    font-weight: 500;
    line-height: 3.5rem;
    letter-spacing: 0px;
    font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
    text-align: center;
    background: #f6f6f6!important;
    padding: 8px!important;
}

#mm-account-details-section{
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
}

#mm-billing-shipping-info-section{
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
}

#mm-subscription-info-section{
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
}

#mm-order-history-section{
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 1066px;
  }
}


.mm-myaccount-block {
    @include media('xs') {
      width: 100%!important;
    }
    @include media('sm') {
      width: 100%!important;
    }
    @include media('md') {
      width: 50%!important;
    }
}