#content,
#main_header,
#pre-foot {
	transition: all 2s ease 0.5s;
	top: 0;
	position: relative;
}

#headerwrapper {
	position: relative;
}

.header {
	height: flex;
}

.header_wrapper {
	@include media('xs') {
		height: auto;
		min-height: 112px;
	}
	@include media('sm') {
		height: auto;
		min-height: 112px;
	}
	@include media('md') {
		height: rem(155px);
	}
	max-width: 100vw;
	min-width: 100vw;
	background-color: $transparent;
	position: fixed;
	z-index: 999;
	//background-image: linear-gradient(180deg, #000000 0%, transparent 81%, rgba(216, 216, 216, 0) 77%);
	background-image: linear-gradient(180deg, #000 0%, #0a0a0a 81%, rgba(10,10,10,0.0) 77%);
	background-position: center;
	background-size: cover;
	& .header_inner {
		height: 100%;
		@include media('xs') {
			width: 100%;
		}
		@include media('sm') {
			width: 100%;
		}
		@include media('md') {
			width: $site-width;
		}
		margin: 0 auto;
		& .logo {
			float: left;
			margin: 0 auto;
			background-image: url(images/logo.svg);
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;
			height: 100%;
			cursor: pointer;
			@include media('xs') {
				min-width: 55%;
				max-width: 78%;
				padding: 30px;
				background-size: 75%;
			}
			@media only screen and (max-width: 375px) {
				min-width: 75%;
			}
			@include media('sm') {
				min-width: 55%;
				padding: 30px;
				background-size: 75%;
			}
			@include media('md') {
				min-width: 14vw;
				padding: 1.25rem;
				background-size: contain;
				margin-top:-10px;
			}
		}//logo
		//***NAVIGATION BELOW****
	}//header_inner
}

#nav-main{
	padding-top: rem(58px);
	text-align: right;
	padding-left: rem(0px);
	@include media('xs') {
		display: none;
		width: 0px;
	}
	@include media('sm') {
		display: none;
		width: 0px;
	}
	@include media('md') {
		display: block;
		width: auto;
	}
	& a{
		text-transform: uppercase;
		font-size: rem(14px);
		letter-spacing: rem(2.5px);
		margin-left: rem(45px);
		color: $color-white;
		font-weight: 600;
		&:first-child{
			margin-left: rem(0px);
		}
	}
}

#menu-login{
	&::before{
		content:"\f023";
		font-family: "FontAwesome";
		transform: translateX(rem(-16px));
		position: absolute;
	}
}

.item_disabled{
	cursor: none;
	pointer-events: none;
	opacity: 0.3;
}

.mobileNav{
	@include media('xs') {
		display: block;
	}
	@include media('sm') {
		display: block;
	}
	@include media('md') {
		display: none;
	}
	transform: translate( 26px, 0px );
	width: 50px;
	height: 50px;
	min-width: 50px;
	max-width: 50px;
	margin-top: 15px;
	background-color: rgba(0,0,0,0);
	border: 0px solid white;
	cursor: pointer;
	&::before{
		content: "\f0c9";
		font-family: "FontAwesome";
		color: white;
		font-size: 29px;
	}
}


.menu-model{
	@include media('xs') {
		display: block;
	}
	@include media('sm') {
		display: block;
	}
	@include media('md') {
		display: none;
	}
	pointer-events: all;
	min-height: 100vh;
	max-width: 100vw;
	min-width: 100vw;
	background-color: rgba(0,0,0,0.9);
	position: fixed;
	opacity: 1;
	-khtml-opacity: 0.5;
	visibility: visible;
	z-index: 99999999;
	transition: opacity 0.6s ease-in-out;
	padding: 10px;
	&-hidden{
		opacity: 0;
		pointer-events: none;
	}
	& .exit{
		position: absolute;
		right: 7px;
		top: 11px;
		width: 50px;
		min-width: 50px;
		max-width: 50px;
		border: 0px solid white;
		background-color: rgba(0,0,0,0);
		&::before{
			color: white;
			content: "\f00d";
			font-family: "FontAwesome";
			font-size: 35px;
		}
	}
	&-inner{
		width: 100%;
		min-height: 100vh;
		padding-top: 90px;
		& li{
			width: 100%;
			text-align: center;
			& a{
				font-size: 26px;
				color: white;
				line-height: 60px;
			}
		}
	}
}
