.modal {
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  padding-top: 60px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.75); /* Black w/ opacity */
}

.modal_cover{
  &_inner{
    position: relative;
    margin-top: rem(30px);
    text-align: center;
    margin: 0 auto;
    width: rem(882px);
    @include media('xs') {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @include media('sm') {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @include media('md') {
      width: rem(882px);
      padding-left: 120px;
      padding-right: 120px;
    }
    height: auto;
    min-height: 400px;
    border-radius: rem(13px);
    border: 1px solid #979797;
    background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 60px;

    & h5{

    }
    & h3{

    }
    & p{

    }
    
    .nf-field-container{
      margin-bottom: 0px;
    }

    .nf-field-element{
      & h1{
        font-size: 2.525rem;
        font-weight: 700;
        line-height: 3.5rem;
        font-family: "Gotham Rounded Book", 'Varela Round', sans-serif;
      }
      & p{
        font-size: 17px;
      }
      select {
        -webkit-appearance: none;
        -webkit-border-radius: 0px; 
       }
      .checkbox-wrap{
       .nf-field-element{
         padding-top:19px;
       } 
      }
    }
    #nf-field-16{
      cursor: pointer;
      height: 3.5rem;
      border-radius: 1.8125rem;
      min-width: 18.5625rem;
      width: flex;
      text-align: center;
      outline: 0;
      font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      width: 100%;
      border-width: 1;
      border-color: #FF801B;
      color: #FF801B;
      background-color: rgba(255, 255, 255, 0);
      transition: all 0.5s ease;
      transition-delay: 0.2;
    }
  }
}
.label-right .field-wrap, .label-right .nf-field-label{
  justify-content: center;
}

.field-wrap>div input[type=checkbox]{
  margin-top: 19px!important;
  @include media('xs') {
    margin-top: 2px!important;
  }
  @include media('sm') {
    margin-top: 2px!important;
  }
  @include media('md') {
    margin-top: 19px!important;
  }
}