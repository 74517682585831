/******************
*
*
*	Footer quicklinks
*
*
******************/

.quicklinks_container {
	height: $quicklinks-height;
	width: 100%;
	background-color: $quicklinks-bg-color;
}

.quicklinks_inner {
	width: $site-width;
	@include media('xs') {
		width: 100%;
	}
	@include media('sm') {
		width: 100%;
	}
	@include media('md') {
		width: $site-width;
	}
	margin: 0 auto;
	height: 100%;
	background-color: $quicklinks-container-bg-color;
	padding-top: calc(#{$quicklinks-inner-calc} / 2);
	& .quicklink_item {
		height: 100%;
		float: left;
		background-size: cover;
		opacity: 1;
		&:hover {
			opacity: 1;
		}
	}
	& .quicklink_item-button {
		float: left;
		background-color: $color-green--mid;
		background-size: cover;
		height: $quicklinks-button-height;
		text-align: center;
		margin-top: rem(25px);
		border-style: solid;
		border-width: rem(2px);
		border-radius: rem(10px);
		border-color: $color-green--light;
		margin: 0 rem(10px);
		& a {
			color: $color-green--light;
		}
	}
}

.quicklink_item-button_a {
	color: white;
	&:hover{
		& .quicklink_item-button {
			background-color: $color-navy--light;
			border-color: $color-navy--dark;
			opacity: 0.75;
			& .quicklink_button_left {
				background-color: $color-navy--dark;
			}
		}
	&:active{
		& .quicklink_item-button {
			opacity: 1;
			border-width: rem(4px);
		}
	}
	}
}

.quicklink_button {
	text-align: left;
	position: relative;
	& p {
		margin-top: rem(20px);
		text-align: left;
		font-size: rem(16px);
		color: white;
		text-transform: uppercase;
		line-height: rem(20px);
	}
	& .fa {
		color: white;
		font-size: rem(30px);
		width: rem(50px);
		text-align: center;
		margin-top: rem(10px);
	}
}

.quicklink_button_left {
	width: 30%;
	float: left;
	display: inline;
	background-color: $color-green--dark;
	border-radius: 100%;
	height: 80%;
	padding: rem(10px);
	margin: 4%;
}

.quicklink_button_right {
	width: 60%;
	float: right;
	display: inline;
}
