.join_taster_side-block{
  margin: 30px 0px;

  &_inner{
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
    color: $color-white;
    padding-top: 54px;
    padding-bottom: 49px;
    & h5{
      margin-bottom: 13px;
      font-size: 15px;
      text-transform: uppercase;
    }
    & h2{
      margin-bottom: 11px;
      color: $color-white;
      font-size: 38px;
      line-height: 1;
    }
    & p{
      margin-top: 19px;
      color: $color-white;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
