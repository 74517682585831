
h5{
	font-size: 17px;
}

h3{
	font-size: 32px;
	color: black;
	font-weight: 700;
	margin-bottom: 25px;
	margin-top: 25px;
}

.nf-field-element{
	& p{
		font-size: 17px;
	}
}

.nf-field-label{
	width: 100%;
}
