.members_heading_container{
  width: 100%;
  height: rem(563px);
  padding-top: rem(235px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  &::before{
    content: " ";
    width: 100%;
    height: 100%;
    @include media('xs') {
      //max-height: 275px;
    }
    @include media('sm') {
      //max-height: 275px;
    }
    @include media('md') {
      //max-height: 423px;
    }
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
  }
  &_inner{
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    & h5{
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(3.6px);
      font-weight: 600;
      color: $color-orange;
    }
    & h1{
      margin-top: rem(17px);
      font-size: rem(53px);
      text-transform: capitalize;
      color: $color-white;
    }
  }
}

.member_heading_container{
  width: 100%;





  @include media('xs') {
    height: 563px;
  }
  @include media('sm') {
    height: 563px;
  }
  @include media('md') {
    height: rem(423px);
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: rem(130px);
  &::before{
    content: " ";
    width: 100%;
    height: 100%;
    @include media('xs') {
      max-height: 563px;
    }
    @include media('sm') {
      max-height: 563px;
    }
    @include media('md') {
      max-height: 423px;
    }
    //height: rem(423px);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
  }
  &_inner{
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    & h6{
      text-transform: uppercase;
      margin-top: rem(23px);
      font-size: rem(12px);
      letter-spacing: 0.225rem;
      font-weight: 600;
      color: $color-orange;
    }
    & h2{
      margin-top: rem(9px);
  font-size: rem(41px);
      text-transform: capitalize;
      color: $color-white;
    }
  }
}

.members_menu{
  margin-top: rem(25px);
  & button{
    @include media('xs') {
      margin-bottom: rem(20px);
      margin-right: rem(0px);
    }
    @include media('sm') {
      margin-bottom: rem(20px);
      margin-right: rem(0px);
    }
    @include media('md') {
      margin-bottom: rem(20px);
      margin-right: rem(20px);
    }
    &:last-child{
      margin-right: 0px;

    }
  }
}
