/******************
*
*
*  Mixins'
*
*
******************/

/* =======================================================================
## ++ Media Queries
========================================================================== */


/* Media queries.
@mixin media($break) {
	$value: map-get($breakpoint, $break);
	$xs: map-get($breakpoint, 'xs');

	@if $value == $xs {
		@media (max-width: $value) {
			@content;
		}
	}
	@else {
		@media (min-width: $value) {
			@content;
		}
	}
}
*/
// Media queries.
@mixin media($break) {
	$value: map-get($breakpoint, $break);
	$xs: map-get($breakpoint, 'xs');

	@if $value == $xs {
		@media (max-width: $value) {
			@content;
		}
	}
	@else {
		@media (min-width: $value) {
			@content;
		}
	}
}

@mixin full-width {
  width: 100%;
  float: none;
  clear: both;
}

@mixin small-full {
  @media (max-width: $breakpoint-xs) {
    @include full-width;
  } // @media small
}


$fontsize : 14;
@function rem($pixel){
        @return $pixel / $fontsize + rem;
    }

/* =======================================================================
## ++ Unit transform
========================================================================== */
/*
    Used for making containers have width in percentages.
    Usage: define elemnt width in px and the width of parent elemnt in px.
    eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}
*/
@function cp($target, $container) {
  @return ($target / $container) * 100%;
}
/*
    Used for making px values convert to rem values
    Usage: define font-size in px and it will convert to rems
    eg. font-size: rem(14px);
*/
@function rem($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0
  }
  @return $target / $context + 0rem;
}
$base-font-size: 16px;
