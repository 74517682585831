
// welcome section:

.sign_up{
  width: 100%;
  height: auto;
  background-color: $color-white;
  &_inner{
    width: rem(1235px);
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: rem(1235px);
    }
    height: 100%;
    margin: 0 auto;
    display: flex;
     @include media('xs') {
        flex-direction: column;
        transform: translateY(rem(0px));
      }
      @include media('sm') {
        flex-direction: column;
        transform: translateY(rem(0px));
      }
      @include media('md') {
        flex-direction: row;
        transform: translateY(rem(54px));
      }
    &_left{
      height: auto;
      width: 61%;
      @include media('xs') {
        width: 100%;
      }
      @include media('sm') {
        width: 100%;
      }
      @include media('md') {
        width: 61%;
      }
      float: left;
    }
    &_right{
      width: 39%;
      height: auto;
      @include media('xs') {
        width: 100%;
      }
      @include media('sm') {
        width: 100%;
      }
      @include media('md') {
        width: 39%;
      }
      float: left;
      background-color: $color-orange;
      color: $color-white;
      text-align: center;
      padding: rem(50px);
      //padding-top: rem(109px);
      //padding-bottom: 7.8125rem;
      & h2{
        margin-bottom: rem(9px);
      }
      & hr{
        width: rem(52px);
        margin: 0 auto;
        margin-bottom: rem(30px);
      }
      & p{
        color: $color-white;
        margin-bottom: rem(30px);
      }
      & .price{
        margin-top: rem(9px);
        font-size: rem(58px);
        font-weight: 600;
        margin-bottom: rem(24px);
        & sub{
          font-size: rem(28px);
        }
      }
    }
  }
}

.signup_inner{
  @include media('xs') {
    padding-top: rem(50px);
    padding-bottom: rem(10px);
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  @include media('sm') {
    padding-top: rem(50px);
    padding-bottom: rem(10px);
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  @include media('md') {
    padding-top: rem(91px);
    padding-bottom: rem(91px);
    padding-left: rem(114px);
    padding-right: rem(137px);
  }
  text-align: center;
  & h6{
    text-transform: uppercase;
    letter-spacing: rem(1.2px);
    color: $color-grey--light;
    @include media('xs') {
      font-size: rem(12px);
      margin-top: 30px;
    }
    @include media('sm') {
      font-size: rem(12px);
      margin-top: 30px;
    }
    @include media('md') {
      font-size: rem(14px);
      margin-top: 10px;
    }
  }
  & h2{
    margin-bottom: rem( 16.5px);
    color: $color-black;
    letter-spacing: 0px;
    line-height: 0.9;
    @include media('xs') {
      font-size: 2.3rem;
      margin-top: 10px;
    }
    @include media('sm') {
      font-size: 2.3rem;
      margin-top: 10px;
    }
    @include media('md') {
      font-size: rem(32.5px);
      margin-top: 0px;
    }
  }
  & p{
    font-size: rem(16px);
    color: $color-grey--mid;
    margin-bottom: rem(48px);
  }
  & ul{
    & li{
      margin-bottom: rem(23px);
      font-weight: 600;
      letter-spacing: rem(0.5px);
      &::before{
        content: "\f061";
        font-family: "FontAwesome";
        color: $color-orange;
      }
    }
  }
}
