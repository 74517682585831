/******************
*
*
*  GLOBAL VARIABLES LIKE COLOURS ETC...
*  When changing colour names,
*  Be sure to find and replace all the old colour names.
*
*
******************/


/* ==== Colour settings ====*/

/* Please use proper naming conventions for future fonts and colour variables, e.g.

	$color-red-dark
	$color-red-mid #never use just 'red'. 3 exceptions being. $color-white, $color-black, $color-transparent
	$color-red-light
	$color-red-light--alt # if you have a secondary light red, i know this can be confusing, work with it, see what happens.
	$color-brand
	$color-brand--accent
	$color-background
	$color-background--alt

Same for fonts:

	$font-base #Always prefix with 'font'
	$font-body
	$font-feature

if in doubt, theres a pretty good ref guide here:
http://domain7.github.io/dev-wiki/css/
*/

$color-orange:#FF801B;
$color-orange-grad:linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
$color-grey--dark:#060606;
$color-grey--mid:#6b6b6b;
$color-grey--light:#aaaaaa;
$color-black:#070707;

$color-blue--lighter:#e8f6ff;
$color-blue--light:#dceffb;
$color-navy--light:#2475bc;
$color-navy--dark:#16558d;
$color-green--light:#82d255;
$color-green--mid:#6cc03d;
$color-green--dark:#4ca917;
$color-grey--light:#90959f;
$color-grey--dark:#565656;

$color-white:#fff;
$color-transparent:rgba(255,255,255,0);
$transparent:$color-transparent;

// Standardised colours:

$color-primary:$color-orange;
$color-primary--light: $color-blue--light;
$color-alt: $color-green--mid;


/* Breakpoints:
* to use:
*.some-module {
*   padding: 10px;
*   @media (min-width: breakpoint(large)) { ...
*/
$breakpoint: (
  /*xxlarge: 1600px,
  xlarge: 1400px,
  large: 1200px,
  medium: 1000px,
  small : 800px,
  xsmall: 600px,
  xs: 400px*/
  xs: 575px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/* ============ General Settings ============ */

$main-background-color: $color-white;
$main-page-background-color: $color-white;

/* ------------ base widths ----------- */

// Depreciated!!!! PHASE OUT!! use breakpoints up top -^
$site-width: rem(1440px);
/* ^- this is the main, full size width (after wrap),
		use mixins for the responsive settings in each element, e.g.
		`@include tablets{
				width:90%;
		}` phones | tablets | lowresmonitors
*/

/* ------------ Site Header ------------ */

$header-bg-colour: white;
$logo_position:center;
$logo: 'images/logo.svg';


/* ------------ Nav Bar ------------ */

$nav_bar_height: rem(70px);

$nav-bg-colour: $color-navy--light; /* can be adjusted for outside colours - check _header.scss*/
$nav-bg-colour-hover: $color-navy--dark;
$nav-text-colour: $color-blue--lighter;
$nav-font-size: rem(20px);

$nav-bar-item-spacer-char: '';
$nav-bar-item-spacer-color: #ebdf58;

/* dropdwons stuff */

$dropdown-width: rem(220px)!important;


/* ------------ Sidebar ------------ */

/* set sidebar height HACKY HACKY HACKY! fix somepoint*/

$sidebar-height: rem(100px);
$sidebar_item_height: rem(64px);

/*
	Sidebar, left or right
							*/

$sidebar-side: left;
$sidebar-width: 37%;

/*
	Sidebar, menu header settings
							*/

$sidebar-title-size: rem(28px);
$sidebar-title-align: center;
$sidebar-title-color: $red;
$sidebar-title-bg: white;

/*
	Sidebar, menu item settings
							*/

$sidebar-text-align: center;
$sidebar-bg: $color-white;
$sidebar-bg-hover: $color-navy--light;
$sidebar-text: $color-grey--light;
$sidebar-text-hover: $color-blue--lighter;

/* ------------



	General Page





	------------ */

/*
	page header image
							*/

$page-header-height: 226px;


/* ------------- quicklinks -----------------*/


 $quicklinks-height: 170px; /* 90 for button 190px for photo seems to work well?  */
 $quicklinks-button-height:90px;
 $quicklinks-bg-color: $color-green--dark;
 $quicklinks-container-bg-color: $color-green--dark;

 $quicklinks-inner-calc: calc(#{$quicklinks-height} - #{$quicklinks-button-height}); //(dont edit this)


/* ------------ Footer and Pre-footer ------------ */


/*
	pre footer
							*/


$pre-footer-height: 150px;
$pre-footer-bg-color: $color-blue--lighter;

/*
	 footer
							*/

$footer-nav_width-adjust: 300px;
$footer-height: 40px;
$footer-bg-color: $color-blue--lighter;

/* ------------




	Home Page Page





	------------ */

/*
	Header Slider?
							*/

$home_slider_height: 650px;;

/*
	News and events section
							*/

$news-and-events-height:  400px;

$news-and-events-bg-colour: $color-blue--light; /*main bg for both*/

$news-bg-colour: $color-blue--light;
$events-bg-colour: $color-blue--lighter;

$which_side_for_events: right; //left or right.. duh.

$font-size-for-viewall: 18px;

$alternate-news-itmes-colours: y; //(y)es or (n)o
$latest-news-item-bg-colour: rgba(225,225,225,0.5);
$latest-news-item-bg-colour-alt: rgba(225,225,225,0.2);
$latest-news-item-bg-colour_hover: rgba(225,225,225,0.2);
$latest-news-item-bg-colour-alt_hover: rgba(225,225,225,0.5);

$home_news_date_colour: $color-white;
$home_news_date_align: center;
$home_news_date_font_size: 18px;

$home_news_title_colour: $color-grey--dark;
$home_news_title_align: center;
$home_news_title_font_size: 28px;

$home_news_content_colour: $red;
$home_news_content_align: center;
$home_news_content_font_size: 15px;

$home_news_read_colour: $red;
$home_news_read_align: right;
$home_news_read_font_size: 20px;



/* ------------




	Latest News Page





	------------ */

/*
	Display Date?
							*/

$display-news-date: y; //(y)es or (n)o



/* ------------




	Gallery Page






	------------ */



/*
	number in column?
							*/

$gallery-column-width: 3;

/* image height?

	3 colums best at 160px;
	2 columns best at 190px;
	will automate!!!

	 */

$gallery-column-height: 160px;
