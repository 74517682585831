/******************
*
*
*  News page
*
*
******************/

.latest_news_single {
  margin: rem(30px) rem(0px);
  background-color: $color-blue--light;
  padding: rem(20px);
  border-radius: rem(20px);
  &:hover {
    background-color: $color-green--light;
  }
  & a {
    & h2 {
      font-size: rem(25px);
      color: $color-navy--dark;
    }
  }
}

.news_date {
  font-size: rem(15px);
  margin: rem(10px) rem(0px);
  color: $color-navy--dark!important;
}
