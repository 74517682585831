.sidebar_location{
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 30px;
  padding-top: 55px;
  padding-bottom: 45px;
  & h5{
    margin-bottom: 22px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  & h3{
    font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
    margin-bottom: 24px;
    font-size: 34px;
    font-weight: 500;
  }
  & p{
    font-weight: 600;
    margin-bottom: 6px;
  }
}
