
/******************
*
*
*  Footer and pre-footer stuff in here:
*
*
******************/

.footer{
  width: 100%;
  height: auto;
  background-color: $color-black;
  @include media('xs') {
    min-height: rem(324px);
  }
  @include media('sm') {
    min-height: rem(324px);
  }
  @include media('md') {
    min-height: rem(524px);
  }
  &_contain{
    width: rem(779px);
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('xlarge') {
      width: rem(779px);
    }
    min-height: 100%;
    padding-top: rem(16px);
    margin: 0 auto;
    & hr{
      background-color: whitesmoke;
      border: none;
      display: block;
      height: rem(1px);
      margin: 1.8rem auto;
      opacity: 0.2;
      @include media('xs') {
        width: 50%;
        display: none;
      }
      @include media('sm') {
        width: 50%;
        display: none;
      }
      @include media('md') {
        width: 100%;
        display: block;
      }
      margin-bottom: 2.2rem;
    }
    &_menu{
      @include media('xs') {
        display: none;
      }
      @include media('sm') {
        display: none;
      }
      @include media('md') {
        display: block;
      }
      #nav-footer{
        text-align: center;
        a{
          text-transform: uppercase;
          margin-right: rem(42.4px);
          font-size: rem(14px);
          letter-spacing: rem(2.7px);
          color: $color-white;
          font-weight: 600;
          &:last-child{
            margin-right: rem(0px);
          }
        }
      }
    }
    &_subscribe{
      text-align: center;
      & h2{
        font-size: rem(28px);
        font-weight: 500;
        line-height: rem(56px);
        letter-spacing: rem(0px);
        color: white;
        margin-bottom: rem(5px);
      }
      & p{
        margin-bottom: rem(21px);
      }
      & form{
        width: 100%;
        & input{
          @include media('xs') {
            min-width: 16.9375rem;
            max-width: 100%;
            margin-bottom: 30px;
            margin-right: rem(0px);
          }
          @include media('sm') {
            min-width: 16.9375rem;
            max-width: 100%;
            margin-bottom: 30px;
            margin-right: rem(0px);
          }
          @include media('md') {
            min-width: rem(551px);
            max-width: rem(551px);
            margin-bottom: 0px;
            margin-right: rem(15px);
          }
          min-height: rem(49px);
          border-radius: rem(25px);
          background-color: #ffffff;
          border-width: rem(0px);
          padding-left: rem(28px);
          font-size: rem(15px);
        }
        & button{
          min-width: rem(183px);
          width: rem(183px);
          min-height: 50px;
          max-height: 50px;
          @include media('xs') {
            min-width: 83%;
            max-width: 83%;
          }
          @include media('sm') {
            min-width: 83%;
            max-width: 83%;
          }
          @include media('md') {
            min-width: rem(183px);
            width: rem(183px);
          }
        }
      }
    }//subscribe
    &_social{
      height: auto;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 40px;
      & .facebook{
        margin-right: 10px;
        &::before{
          content: "\f082";
          font-family: 'FontAwesome';
          color: $color-orange;
          font-size: 40px;
        }
      }
      & .twitter{
        &::before{
          content: "\f081";
          font-family: 'FontAwesome';
          color: $color-orange;
          font-size: 40px;
        }
      }
    }
    &_second_menu{
      text-align: center;
      & p{
        margin-bottom: 2px;
        & a{
          font-size: 13px;
        }
      }
    }
    &_third_menu{
      text-align: center;
    }
  }//contain
}
