.members_calendar{
  &_top{
    &_left{
      width: 25%;
      float: left;
    }
    &_middle{
      width: 50%;
      float: left;
      & p{
        text-align: center;
        color: #aaaaaa;
        font-family: "Gotham Rounded Medium";
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
      }
      & h3{
        text-align: center;
        color: #272727;
        font-family: "Gotham Rounded Medium";
        font-size: 29px;
        font-weight: 500;
        line-height: 56px;
      }
    }
    &_right{
      width: auto;
      float: right;
      & button{
        padding-top: 2px;
        &:last-child{
          margin-left: 4px;
        }
      }
    }
  }
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: 987px;
  }
  margin: 0 auto;
  & table{
    margin: 0 auto;
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: 985px;
    }
    & thead{
      height: 54px;
      display: table-header-group;
      border-radius: 13px 13px 0 0;
      background-image: linear-gradient(to right, #ffb73a 0%, #ff801b 100%);
      & tr{
        height: 54px;
        & th{
          color: #ffffff;
          font-family: "Gotham Rounded Book";
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          text-transform: uppercase;
          padding-top: 14px;
          width: 14.28%;
          min-width: 14.28%;
          max-width: 14.28%;
          text-align: center;
          &:first-child {
            border-top-left-radius: 13px;
          }
          &:last-child {
            border-top-right-radius: 13px;
          }
        }
      }
    }
    & tbody{
      & tr{
        min-height: 14px;
        height: 14px;
        & td{
          padding: 9px;
          padding-top: 5px;
          width: 14.28%;
          min-width: 14.28%;
          max-width: 14.28%;
          text-align: right;
          color: #6b6b6b;
          font-family: "Open Sans";
          font-size: 22px;
          line-height: 26px;
          border: 1px solid #d9d9d9;
          & .event{
            margin-top: 15px;
            width: 121px;
            height: 29px;
            border-radius: 4px;
            background-color: #1d3247;
            & p{
              color: #ffffff;
              font-family: "Open Sans";
              font-size: 14px;
              line-height: 26px;
              text-align: center;
            }
          }
        }
        & .empty{
          background-color: #f5f5f5;
          color: #c6c6c6;
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
  }
}

// overwriting dan's calendar.

.fc-toolbar{
  & .fc-left{
    @include media('xs') {
      width: 50%;
    }
    @include media('sm') {
      width: 50%;
    }
    @include media('md') {
      width: 33%;
    }
  }
  & .fc-right{
    & .fc-button-group{
      float: right;
    }
    @include media('xs') {
      width: 50%;
    }
    @include media('sm') {
      width: 50%;
    }
    @include media('md') {
      width: 33%;
    }
  }
  & .fc-center{
    text-align: center;
    & h2{
      width: 100%;
    }
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: 33%;
    }
  }
  & button{
    width: 175px;
    min-width: 175px;
    max-width: 175px;
    border-width: 1px;
    border-color: #FF801B;
    color: #FF801B;
    background-color: rgba(255,255,255,0);
    transition: all 0.5s ease;
    transition-delay: 0.2;
    &:hover{
      border-width: 0;
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: #FF801B;
      text-shadow: 0 0.0625rem 0.125rem rgba(0,0,0,0.5);
      color: #fff;
    }
  }
  & .fc-prev-button, .fc-next-button {
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
    width: 45px;
    margin-left: 5px!important;
    max-width: 46px;
    min-width: 46px;
    font-size: 35px;
    height: 47px;
    @include media('xs') {
      transform: translate(-3px, 0px);
    }
    @include media('sm') {
      transform: translate(-3px, 0px);
    }
    @include media('md') {
      transform: translate(-3px, 0px);
    }

    background-color: white;
    background-image: none;
    padding: 0px;
    & span{
      display: none;
    }
  }
  & .fc-prev-button{
    &:after{
      content: "\f104";
      font-family: "FontAwesome";
    }
  }
  & .fc-next-button{
    &:after{
      content: "\f105";
      font-family: "FontAwesome";
    }
  }
  &.fc-corner-left, .fc-corner-right{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  & .fc-state-hover{
    color:#333;
    text-decoration:none;
    background-position:0 0px;
    -webkit-transition:background-position .1s linear;
    -moz-transition:background-position .1s linear;
    -o-transition:background-position .1s linear;
    transition:background-position .1s linear}
}

.fc{
  max-width: 100%;
  & .fc-head{
    border-color: rgba(0,0,0,0)!important;
  }
  & .fc-head-container{
    background-color: white;
    border: 0px!important;
    & .fc-widget-header{
        border-width: 0px!important;
        border-style: none!important;
        border-color: rgba(0,0,0,0)!important;
      & table{
        & thead{
          & tr{
            border-width: 0px!important;
            border-style: none!important;
            border-color: rgba(0,0,0,0)!important;
          }
        }
      }
    }
  }
  
  & .fc-body{
    & .fc-widget-content{
      padding: 0px;
    }
  }
  .members_calendar table tbody tr td{
    border: 1px solid black;
  }
  & .fc-content-skeleton{
    & thead{
      height: 15px;
      display: contents;
    }
  }
  .fc-other-month{
    background-color: #f5f5f5;
  }
}