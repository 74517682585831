.home_page_slider{
  width: 100%;
  height: rem(810px);
  background-color: $color-black;
  background-image: url('images/temp_slider.png');
  background-size: cover;
  background-position: center;
  display: block;
  padding-top: rem(289px);
  @include media('xs') {
    padding-top: 9.0625rem;
    height: 46.625rem;
  }
  @include media('sm') {
    padding-top: 9.0625rem;
    height: 46.625rem;

  }
  @include media('md') {
    padding-top: rem(289px);
    height: rem(810px);
  }
  &_inner{
    width: rem(1234px);
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      max-width: rem(1234px);
    }
    height: rem(244px);
    margin: 0 auto;
    text-align: center;
    & h5{
      text-transform: uppercase;
      font-size: rem(13px);
      color: $color-orange;
      font-weight: 600;
      letter-spacing: rem(4px);
    }
    & h1{
      margin-bottom: rem(37px);
      color: $color-white;
       @include media('xs') {
        margin-top: 1.5rem;
        font-size: 2.8rem;
        line-height: 3.0rem;
      }
      @include media('sm') {
        margin-top: 1.5rem;
        font-size: 2.8rem;
        line-height: 3.0rem;
      }
      @include media('md') {
        margin-top: rem(10px); 
        font-size: 3.125rem;
        line-height: 3.5rem;
      }
    }
    & button{
      margin-left:rem(17px);
      margin-bottom: 30px;
      &:first-child{
        margin-left:rem(0px);
      }
      @include media('xs') {
        padding-left: 25px;
        padding-right: 25px;
      }
      @include media('sm') {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}
