
/******************
*
*
*  ALERTS
*
*
******************/

.alert {
  width: rem(150px);
  height: rem(150px);
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  opacity: 0.8;
}

.icon_container {
  width: 100%;
  height: 100%;
}

.alert_icon {
  margin-left: rem(20px);
  margin-top: rem(65px);
  font-family: FontAwesome;
  font-size: rem(60px);
  color: white;
  text-align: center;
  width: rem(65px);
}

.alert_text {
  margin-top: rem(4px);
  width: rem(110px);
  font-size: rem(14px);
  color: white;
  text-align: center;
}
