/******************
*
*
*	Sidebar settings;
*
*
******************/

.content-wrapper {
	width: 100%;
}

.article-content{
	width: 100%;
	float: left;
	padding-left: rem(0px);
	padding-right: rem(0px);
	padding-top: rem(0px);
}

.blog-content{
	@include media('xs') {
		padding: 25px;
		width: 100%;
	}
	@include media('sm') {
		padding: 25px;
		width: 100%;
	}
	@include media('md') {
		padding: 25px;
		padding-left: rem(90px);
		width: calc(100% - #{$sidebar-width})!important;
	}
	float: left;
	padding-right: rem(0px);
	padding-top: rem(60px);
	margin-bottom: 60px;
	& .catagory{
		text-transform: uppercase;
		margin-top: 43px;
		color: #AAAAAA;
		margin-bottom: 20px;
	}
}

.sidebar {
	background-color: $sidebar-bg;
	width: $sidebar-width;
	float: $sidebar-side;
	height: $sidebar-height;
	position: relative;
	padding-bottom: 900%;
	margin-bottom: -900%;
	padding-left: 40px;
	padding-right: 92px;
	padding-top: 30px;
	& h2 {
		font-size: $sidebar-title-size;
		text-align: $sidebar-title-align;
		color: $sidebar-title-color;
	}
	& .side-title {
		background-color: $sidebar-title-bg;
		height: rem(60px);
		& h2 {
			padding-top: rem(15px);
		}
	}
}

.menu {
	background-color: $color-blue--light;
	padding-top: calc(#{$sidebar_item_height} / 2);
	& .menu-item {
		text-align: $sidebar-text-align;
		padding: rem(20px);
		background-color: $sidebar-bg;
		height: $sidebar_item_height;
		&:hover{
			background-color: $sidebar-bg-hover;
			border-radius: rem(10px);
			&:after {
				font-family: FontAwesome;
				color: $color-navy--dark;
				content: "\f0a9";
				display: inline-block;
				font-size: rem(30px);
				padding-top: rem(34px);
				float: right;
				transform: translateY(rem(-37px));
			}
		}
		& a {
			text-align: $sidebar-text-align;
			justify-content: $sidebar-text-align;
		}

	}
	& .current-menu-item{
		background-color: $sidebar-bg-hover!important;
		border-radius: rem(10px);
		&:after {
			font-family: FontAwesome;
			color: $color-navy--dark;
			content: "\f0a9";
			display: inline-block;
			font-size: rem(30px);
			padding-top: rem(34px);
			float: right;
			transform: translateY(rem(-37px));
		}
		& a {
			color: $sidebar-text-hover;
		}
	}
	& li{
		width: 100%;
		& a {
			color: $sidebar-text;
		}
		&:hover{
			& a{
				color: $sidebar-text-hover;
			}
		}
	}
}
