.location{
  width: rem(1234px);
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: rem(1234px);
  }
  height: auto;
  min-height: rem(447px);
  margin: 0 auto;
  margin-top: rem(30px);
  margin-bottom: rem(56px);
  transform: translateY(rem(-84px));
  &_details{
    width: calc( 100% - 553px );
    height: 100%;
    float: left;
    text-align: center;
    padding-top: rem(93px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    padding-bottom: 93px;
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: calc(100% - 553px);
    }
    & h5{
      text-transform: uppercase;
      font-size: rem(14px);
      font-weight: 600;
    }
    & b{
      font-weight: 600;
      width: 100%;
      text-align: center;
      font-family: "Roboto";
    }
  }
  &_map{
    width: rem(553px);
    height: 100%;
    background-color: grey;
    float: left;
    text-align: center;
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: rem(553px);
    }
    & button{
      position: absolute;
      z-index: 99;
      margin-top: rem(30px);
    }
  }
  & .map_over_buttons{
    @include media('xs') {
      width: 100%;
    }
    @include media('sm') {
      width: 100%;
    }
    @include media('md') {
      width: rem(553px);
    }
    height: rem(40px);
    top: rem(30px);
    float: left;
    text-align: center;
    position: absolute;
    right: rem(0px);
    & .btn_extrasmall{
      height: 44px!important;
    }
  }

  & .map_over_details{
    width: rem(453px);
    height: auto;
    padding: rem(30px);
    top: rem(130px);
    float: left;
    text-align: center;
    position: absolute;
    opacity: 0.8;
    border-radius: rem(13px);
    background-color: black;
    transition: opacity 0.3s ease;
    & p{
      color: $color-white;
      margin-bottom: rem(0px);
    }
  }

  &:nth-child(even)>.location_map{
    float: right;
  }

  &:nth-child(odd)>.map_over_details{
    left: rem(50px);
  }

  &:nth-child(even)>.map_over_details{
    float: right;
    right: rem(50px);
  }

  &:nth-child(odd)>.map_over_buttons{
     float: left;
    right: unset;
  }

  &:nth-child(even)>.map_over_buttons{
    float: right;
    left: unset;
  }

  &:last-child{
    margin-bottom: rem(0px);
  }
}

.locations_inner_bottom_buttons{
  & form{
    &:after{
      content: "";
      position: relative;
      right: 20%;
      top: -29px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #333;
      float: right;
    }
    & select{
      -webkit-appearance: none;
      border: 1px solid #FF801B;
      border-radius: 50px;
      background-color: white;
      text-align: center;
      text-align-last:center;
      font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
      font-size: .875rem;
      font-weight: 500;
      letter-spacing: .125rem;
      text-transform: uppercase;
    }
  }
}


#locationDetails {
    > div {
        @extend p;
    }
    .practice-day {
        font-weight: 600;
    }
    .address {
        font-weight: 600;
    }
}
