
// taster session bits
.testimonial_block{
  height: auto;
  width: 100%;
  float: left;
  background-color: black;
  background-image: url(images/joinus_bg.png);
  background-position: center;
  background-size: cover;
  @include media('xs') {
    height: auto;
    padding-bottom: 40px;
    padding-top: rem(40px);
  }
  @include media('sm') {
    height: auto;
    padding-bottom: 40px;
    padding-top: rem(40px);
  }
  @include media('md') {
    min-height: rem(583px);
    padding-bottom: 40px;
    padding-top: rem(80px);
  }
  &_inner{
    box-shadow: rem(0px) rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    border-radius: rem(13px);
    background-color: #000000;
    opacity: 0.95;
    margin: 0 auto;
    text-align: center;
    color: $color-white;
    position: relative;
    @include media('xs') {
      width: 100%;
      height: auto;
      padding: 42px 20px;
    }
    @include media('sm') {
      width: 100%;
      height: auto;
      padding: 42px 20px;
    }
    @include media('md') {
      width: rem(760px);
      height: rem(423px);
      padding: rem(78px) rem(113px);
    }
    &::before{
      @include media('xs') {
        content: '';
      }
      @include media('sm') {
        content: '';
      }
      @include media('md') {
        content: '\“';
      }
      font-family: 'Arial';
      color: $color-orange;
      position: absolute;
      font-size: 140px;
      text-align: left;
      float: left;
      left: 75px;
      top: 75px;
    }
    &::after{
      @include media('xs') {
        content: '';
      }
      @include media('sm') {
        content: '';
      }
      @include media('md') {
        content: '\“';
      }
      font-family: 'Arial';
      color: $color-orange;
      position: absolute;
      font-size: 140px;
      text-align: left;
      float: left;
      right: 75px;
      bottom: 55px;
      transform: rotate(180deg);
    }
    & h5{
      text-transform: uppercase;
      font-size: rem(14px);
      letter-spacing: rem(0.5px);
      margin-bottom: rem(6px);
    }
    & h2{
      margin-bottom: rem(11px);
    }
    & p{
      margin-bottom: rem(27px);
    }
  }
}

.ticker_holder{
  margin: 0 auto;
  height: 30px;
  & .ticker{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1.5px solid white;
    background-color: $transparent;
    float: left;
    margin-right: 5px;
  }
  & .active_ticker{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $color-white;
    float: left;
    margin-right: 5px;
  }
}
