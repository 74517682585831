.faq{
  width: rem(1234px);
  @include media('xs') {
    width: 100%;
    margin-top: 15px;
  }
  @include media('sm') {
    width: 100%;
    margin-top: 15px;
  }
  @include media('md') {
    width: rem(1067px);
    margin-top: 30px;
  }
  height: auto;
  margin: 0 auto;
  z-index: 9;
  position: relative;
  padding-top: rem(0px);
  margin-bottom: rem(108px);
  &_top{
    text-align: center;
    max-width: 100%;
    padding: 30px;
    & h5{
      text-transform: uppercase;
      color: $color-grey--mid;
      font-size: rem(15px);
      margin-bottom: rem(5px);
    }
    & h2{
      color: #000000;
      margin-bottom: rem(11px);
      line-height: 1.2;
      margin-top: 20px;
    }
    & p{
      color: $color-grey--mid;
      margin-bottom: rem(37px);
    }
  }
  &_bottom{
    & .accordion{
      padding: 20px;
      width: rem(1234px);
      @include media('xs') {
        width: 100%;
      }
      @include media('sm') {
        width: 100%;
      }
      @include media('xlarge') {
        width: rem(1234px);
      }
      height: auto;
      padding-left: rem(30px);
      padding-right: rem(30px);
      overflow: hidden;
    }
  }
}

.accordion-header {
  //border-bottom: rem(1px) solid #303030;
  cursor: pointer;
  font-size: 2em;
  padding: rem(10px) 0 rem(10px) rem(0px);
  padding-left: rem(0px);
  padding-right: rem(0px);
  position: relative;
  text-align: left;
  width: 100%;
  padding-top: rem(14px);
  font-size: rem(24px);
  text-transform: none;
  @include media('xs') {
    font-size: rem(18px);
  }
  @include media('sm') {
    font-size: rem(18px);
  }
  @include media('xlarge') {
    font-size: rem(28px);
  }
  &::after {
    content: "\f105";
    color: $color-orange;
    font-family: "FontAwesome";
    float: right;
    margin-right: rem(0px);
    transform: translateY(-5px);
  }
}
.is-active::after {
    content: "-";
}

.accordion-content{
  border-bottom: rem(1px) solid #EAEAEA;
  @include media('xs') {
    text-align: center;
  }
  @include media('sm') {
    text-align: center;
  }
  @include media('md') {
    text-align: left;
  }
  &:last-child{
    border-bottom: rem(0px);
  }
  transition: height 0.3s ease;
  & h2{
    font-size: rem(12px);
    @include media('xs') {
      text-align: center;
    }
    @include media('sm') {
      text-align: center;
    }
    @include media('md') {
      text-align: left;
    }
  }
  & p{
    @include media('xs') {
      text-align: center;
    }
    @include media('sm') {
      text-align: center;
    }
    @include media('md') {
      text-align: left;
    }
  }
}

.hidden{
  height: rem(0px);
  display: block;
  transition: height 0.3s ease;
  & p{
    font-size: rem(0px);
  }
}

.js .accordion-content {
    //display: none
}

.js .accordion-content.is-active {
    border-bottom: rem(1px) solid #303030;
    display: block;
}

.faq_single{
  & .faq_icon{
    //width: 70px;
    height: 100%;
    float: left;
    & .icon{
      width: 70px;
      height: 70px;
      border: 2px solid #FF801B;
      padding: 21px;
      padding-bottom: 16px;
      border-radius: 8px;
      text-align: center;
      margin: 0 auto;
      display: block;
      &::before{
        content: "\f15b";
        font-family: 'FontAwesome';
        color: #FF801B;
        font-size: 29px;
      }
    }
  }
}

.members_acc{
  width: 100%!important;
}
