.pre_blog_posts_wrap{
  width: rem(1245px);
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: rem(1245px);
  }
  height: auto;
  margin: 0 auto;
  text-align: center;
  & h5{
    margin-top: rem(20px);
    text-transform: uppercase;
    color: $color-grey--mid;
    font-size: rem(15px);
    margin-bottom: rem(5px);
  }
  & h2{
    color: $color-grey--dark;
    margin-bottom: rem(11px);
  }
}

.blog_posts_wrap{
  height: auto;
  margin: 0 auto;
  text-align: center;
  margin-bottom: rem(0px);
  margin-top: 90px;
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: rem(1290px);
  }
}

.blog_archive{
  cursor: pointer;
  width: rem(600px);
  max-width: rem(600px);
  height: rem(626px);
  float: left;
  margin-right: rem(45px);
  overflow: hidden;
  margin-bottom: rem(45px);
  @include media('xs') {
    width: 100%;
    max-width: 100%;
  }
  @include media('sm') {
    width: 100%;
    max-width: 100%;
  }
  @include media('md') {
    width: 600px;
    max-width: rem(600px);
  }
  &_left{
    transform: translateY(-30px)!important;
  }

  &_top{
    width: 100%;
    height: 50%;
    background-size: cover;
    background-position: center;
  }
  &_bottom{
    padding: rem(40px);
    & h3{
      font-size: 28px;
      color: #0e0e0e;
      font-weight: 500;
    }
  }
}

.post_blog_posts_wrap{
  padding-top: rem(144px);
  width: rem(1245px);
  @include media('xs') {
    width: 100%;
  }
  @include media('sm') {
    width: 100%;
  }
  @include media('md') {
    width: rem(1245px);
  }
  height: auto;
  margin: 0 auto;
  text-align: center;
  display: block;
  height: rem(238px);
}


//Single blog post:

.article-content{
  & .catagory{
    text-transform: uppercase;
    margin-top: 42px;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  & .article-body{
    & blockquote{
      &::before{
        content: "\f10e";
        color: $color-white;
        font-family: "fontawesome";
        background-color: $color-orange;
        width: 42px;
        text-align: center;
        height: 42px;
        padding: 11px;
        font-size: 22px;
        border-radius: 6px;
        position: absolute;
        left: 32%;
        margin-top: -71px;
      }
      & p{
        margin-top: rem(110px);
        padding-left: rem(35px);
        padding-right: rem(30px);
        text-align: center;
        font-size: rem(21px);
      }
    }
    & h3{
      font-size: rem(29.5px);
      font-weight: 600;
      margin-top: rem(46px);
      margin-bottom: rem(17px);
    }
    & p{
      & a{
        color: $color-orange;
        text-decoration: underline;
      }
    }
    & ul{
      margin-top: rem(31px);
      margin-left: rem(27px);
      list-style: none;
      margin-bottom: 26px;
      & li{
        margin-bottom: rem(11px);
        &::before{
          content: "\f061";
          font-family: 'fontawesome';
          color: $color-orange;
          margin-left: -23px;
          margin-right: 9px;
        }
      }
    }
  }
}
