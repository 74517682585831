/******************
*
*
*  Menu - CURRENTLY REDUNDANT:
*
*
******************/


#menu-login{
  opacity: 0.5;
}

.menu-login{
  font-size: 26px;
  color: white;
  text-align: center;
  line-height: 60px;
}
