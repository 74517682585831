.heads_up{
  width: flex;
  height: auto;
  min-height: 50px;
  border-radius: 7px;
  padding-left: 20px;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 12px;
  & p{
    font-weight: 600;
    margin-bottom: 0.375rem;
  }
  &:hover{
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  &_red{
    border: 1px solid #823c3c;
    background-color: rgba(255, 136, 136, 0.35);
    color: #d94646!important;
    & p{
      color: #d94646!important;
      &::before{
        content: "\f071";
        font-family: 'FontAwesome';
        margin-right: 20px;
      }
    }
  }
  &_green{
    border: 1px solid #3BB35D;
    background-color: rgba(59, 179, 93, 0.35);
    color: #3BB35D!important;
    & p{
      color: #3BB35D!important;
      &::before{
        content: "\f164";
        font-family: 'FontAwesome';
        margin-right: 20px;
      }
    }
  }
  &_yellow{
    border: 1px solid #FFFEA1;
    background-color: rgba(255, 254, 161, 0.35);
    color: #8FCC6C!important;
    & p{
      color: #8FCC6C!important;
      &::before{
        content: "\f05a";
        font-family: 'FontAwesome';
        margin-right: 20px;
      }
    }
  }
  &_blue{
    border: 1px solid #589FCC;
    background-color: rgba(88, 159, 204, 0.35);
    color: #589FCC!important;
    & p{
      color: #589FCC!important;
      &::before{
        content: "\f0e7";
        font-family: 'FontAwesome';
        margin-right: 20px;
      }
    }
  }
}
