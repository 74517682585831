/******************
*
*
*  FONTS
*
******************/

/**** Imports: ****/

@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@font-face {font-family: "Gotham Rounded Medium";
    src: url("./fonts/GothamRounded-Medium.otf");
    font-display: swap;
}
@font-face {font-family: "Gotham Rounded Book";
    src: url("./fonts/GothamRounded-Book.otf");
    font-display: swap;
}

@font-face {font-family: "Gotham Rounded";
    src: url("./fonts/GothamRounded-Medium.otf");
    font-display: swap;
}

/**** Styling: *****/

a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
  font-family: 'Varela Round', sans-serif;
}

.article-body {
  & h3{
    color: #060606;
    font-family: 'Gotham Rounded';
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 19px;
  }
  & blockquote{
    margin-top: 30px;
    margin-bottom: 30px;
    & p{
      color: #6b6b6b;
      font-family: 'Gotham Rounded';
      font-weight: 300;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
    }
  }
  & ul {
    list-style: none;
    margin-left: 0px;
    & li {
      margin-bottom: rem(30px);
      color: $color-grey--dark;
      &::before{
        content: "\f061";
        font-family: 'FontAwesome';
        color: $color-orange;
        margin-right: 20px;
      }
    }
  }
  & ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 10%;
    & li {
      margin-bottom: rem(30px);
      color: $color-grey--dark;
    }
  }
}

a, p {
  font-size: rem(16px);
  line-height: rem(26px);
  text-decoration: none;
  color: $color-grey--mid;
}

a:hover{
  color: $color-orange;
}

h1 {
  font-size: rem(50px);
  font-weight: 300;
  line-height: rem(56px);
  font-family: "Gotham Rounded Book", 'Varela Round', sans-serif;
}

h2 {
  font-size: rem(38px);
  font-weight: 500;
  line-height: rem(56px);
  letter-spacing: rem(1.4px);
  font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
}

h3 {
  font-size: rem(18px);
}

h4 {
  font-size: rem(16px);
  color: $color-green--mid;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

blockquote {
  font-weight: light;
  font-style: italic;
  p{
    font-family: "Open Sans";
    margin-top: 30px;
  }
  &::before{
    content: "\f10d";
    color: white;
    background-color: $color-orange;
    min-width: 30px;
    min-height: 30px;
    border-radius: 7px;
    padding: 10px;
    font-size: 30px;
    font-family: "FontAwesome";
    font-style: normal!important;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 50%;
  }
}

.article-h1 {
  margin-bottom: rem(21px);
  color: #272727;
  font-family: "Gotham Rounded Medium";
  font-size: 38px;
  font-weight: 500;
  line-height: 43px;
}

p {
  font-size: rem(16px);
  color: $color-grey--mid;
  margin-bottom: rem(22px);
}

.news_items {
  & .item{
    & a{
      & h4{
        color: $color-green--mid;
      }
    }
    &:hover {
      & a{
        h4 {
          color: $color-navy--light;
        }
      }
    }
  }
}

.right_header_bottom {
  & p {
   color: $color-grey--light;
   font-size: rem(16px);
 }
}

.left_header_bottom {
  & p {
    color: $color-grey--light;
    font-size: rem(16px);
  }
}

.article-body {
  & p {
    color: #565656;
    line-height: 1.65;
    font-size: 1rem;
    letter-spacing: rem(-0.1px);
  }
}
