.song{
  &_inner{
    & .accordion{
      &_single{
        cursor: pointer;
        padding: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0,0,0, 0.05);
        &:last-child{
          border-bottom: 0px solid grey;
        }
        & .song-image{
          margin-right: 20px;
        }
      }
    }
  }
}


.songs-header {
  cursor: pointer;
  padding: rem(10px) 0 rem(10px) rem(0px);
  padding-left: rem(0px);
  padding-right: rem(0px);
  position: relative;
  text-align: left;
  width: 100%;
  padding-top: rem(34px);
  font-size: rem(28px);
  @include media('xs') {
    font-size: rem(12px);
  }
  @include media('sm') {
    font-size: rem(12px);
  }
  @include media('xlarge') {
    font-size: rem(28px);
  }
  &::after {
    content: "\f105";
    color: $color-orange;
    font-family: "FontAwesome";
    float: right;
    margin-right: rem(0px);
    transform: translateY(15px);
  }
}

.accordion_single{
  .song-content{
    border-bottom: rem(1px) solid #EAEAEA;
    margin-top: -4px;
    min-height: 107px;
    @include media('xs') {
      margin-bottom: 60px;
    }
    @include media('sm') {
      margin-bottom: 60px;
    }
    @include media('md') {
      margin-bottom: 10px;
    }
    &:last-child{
      border-bottom: rem(0px);
    }
    transition: height 0.3s ease;
    & h2{
      text-align: left;
      @include media('xs') {
        font-size: 19px;
        text-align: center;
      }
      @include media('sm') {
        font-size: 19px;
        text-align: center;
      }
      @include media('md') {
        font-size: 29px;
        text-align: left;
      }
    }
    & p{
      margin-bottom: 2px;
      @include media('xs') {
        text-align: center;
      }
      @include media('sm') {
        text-align: center;
      }
      @include media('md') {
        text-align: left;
      }
    }
    & button{
      height: 48px; 
      @include media('xs') {
        margin-top: 15px;
        float: left;
        min-width: 100%; 
      }
      @include media('sm') {
        margin-top: 15px;
        float: left;
        min-width: 100%; 
      }
      @include media('md') {
        margin-top: -64px;
        float: right;
        min-width: 230px; 
      }
    }
  }
}

