/******************
*
*
*  Global Styling - liek page containers etc.
*
*
******************/

html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: $main-background-color;
}

#content {
  height: auto;
  overflow: hidden;
}

.content-wrapper {
  height: auto;
  min-height: 500px;
}

.main_page_wrap {
  overflow: hidden;
}

.article-content::after {
  content: "";
  height: rem(90px);
}

article {
  background-color: $main-page-background-color;
  min-height: 50vh;
  height: auto;
}

* {
  box-sizing: border-box;
}

.block{
  &_shadow{
    box-shadow: rem(0px) rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    @include media('xs') {
      border-radius: rem(0px);
    }
    @include media('sm') {
      border-radius: rem(0px);
    }
    @include media('md') {
      border-radius: rem(13px);
    }
    border: rem(1px) solid #eaeaea;
    background-color: #ffffff;
    &:hover{
      box-shadow: rem(0px) rem(2px) rem(30px) rgba(76, 209, 196, 0.33);
      border: rem(1px) solid #b1dad6;
    }
  }//shadow
  &_shadow_nohover{
    box-shadow: rem(0px) rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    @include media('xs') {
      border-radius: rem(0px);
    }
    @include media('sm') {
      border-radius: rem(0px);
    }
    @include media('md') {
      border-radius: rem(13px);
    }
    border: rem(1px) solid #eaeaea;
    background-color: #ffffff;
    overflow: hidden;
  }//shadow
  &_orange{
    box-shadow: rem(0px) rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    @include media('xs') {
      border-radius: rem(0px);
    }
    @include media('sm') {
      border-radius: rem(0px);
    }
    @include media('md') {
      border-radius: rem(13px);
    }
    background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
  }
  &_dark{
    box-shadow: rem(0px) rem(2px) rem(30px) rgba(0, 0, 0, 0.04);
    @include media('xs') {
      border-radius: rem(0px);
    }
    @include media('sm') {
      border-radius: rem(0px);
    }
    @include media('md') {
      border-radius: rem(13px);
    }
    background-color: rgba(0,0,0,0.95);
  }//dark
  &_hidden{
    background-color: $transparent;
  }
  &_orange_header{
    content: attr(title);
    text-align: center;
    height: 58px;
    width: 100%;
    border-radius: 13px 13px 0 0;
    background-image: linear-gradient(76deg, #ffb73a 0%, #ff801b 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    & h4{
      width: 979px;
      height: auto;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-transform: uppercase;
    }
    &_after{
      min-height: 60px;
      height: auto;
      display: inline-block;
      border-radius: 0px 0px 13px 13px;
      width: 100%;
      background-color: #e8e8e8;
      margin-bottom: 30px;
      @include media('xs') {
        border-radius: 0px 0px 0px 0px;
        padding: 0px;
      }
      @include media('sm') {
        border-radius: 0px 0px 0px 0px;
        padding: 0px;
      }
      @include media('md') {
        border-radius: 0px 0px 13px 13px;
        padding: 20px;
      }
    }
    &_content{
      min-width: 100%;
      min-height: 60px;
      background-color: $color-white;
      border-radius: 15px;
      border: 3px solid #e5e5e5;
    }
  }
}





/* =======================================================================
## ++ Cleafix
========================================================================== */
/* float clearing for IE6 */

* html .clearfix {
  height: 1%;
  overflow: visible;
}

/* float clearing for IE7 */
*+html .clearfix {
  min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0;
}

.clr {
  clear: both;
}


/*#content {
  width: $site-width;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}*/

.article-content {
  padding: rem(30px) rem(0px) rem(30px) rem(30px);
  min-height: rem(700px);
}

.post-wrapper {
  margin: rem(0px);
}

// Clearfix heights:

.height10{
  height: rem(10px);
}
.height20{
  height: rem(20px);
}
.height30{
  height: rem(30px);
}

.post-hidden{
  display: none!important;
}

#wppb-login-wrap{
  .button{
    cursor: pointer;
    height: rem(56px);
    border-radius: rem(29px);
    @include media('xs') {
      min-width: 15.5625rem;
    }
    @include media('sm') {
      min-width: 15.5625rem;
    }
    @include media('md') {
      min-width: rem(297px);
    }
    width: flex;
    text-align: center;
    outline: 0;
    font-family: "Gotham Rounded Medium", 'Varela Round', sans-serif;
    font-size: rem(14px);
    font-weight: 500;
    letter-spacing: rem(2px);
    text-transform: uppercase;
    &:hover {
      background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);
      background-color: $color-orange;
      border-width: 1;
      border-color: $color-white;
      text-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.5);
      color: $color-white;
    }
  }
}

.mm-checkoutInfoBlock{
  border: 0px solid white!important;
  overflow: hidden!important;
}

.mm-checkoutContainer{
  transform: translateY(-55px);
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 55px;
  width: 1234px!important;
  max-width: 1232px!important;
  height: auto;
  min-height: 1731px;
  padding-top: 30px;
  padding: 30px;
  z-index: 9;
  position: relative;
  box-shadow: 0 0.125rem 1.875rem rgba(0, 0, 0, 0.04);
  border-radius: 0.8125rem;
  border: 0.0625rem solid #eaeaea;
  background-color: #ffffff;
  overflow: hidden;
  padding-right: 20px;
}

#mm-account-information-section{
  padding-top: 12px;

  & h3{
    text-transform: uppercase;
    transform: translateX(-4px);
    letter-spacing: 0.7px;
  }
  & h2{
    margin-top: 4px;
    text-align: center;
    margin-bottom: 10px;
    transform: translateX(-5px);
    letter-spacing: 0.2px;
  }
  & .intro{
    width: 700px;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0.2px;
    transform: translateX(28px);
    margin-bottom: 39px;
  }
  & p{
    margin-bottom: 12px;
  }
  & hr{
    width: 590px;
    margin: 0 auto;
  }

  .location_title{
    text-align: center;
    font-size: 19px;
    text-transform: none!important;
    font-weight: 600;
    margin-top: 42px;
    letter-spacing: 1.1px!important;
    margin-bottom: 16px;
  }
}

#mm-billing-information-section{
  & .billing_title{
    text-align: center;
    font-size: 36px;
    text-transform: none!important;
    font-weight: 500;
    margin-top: 3px;
    letter-spacing: 1.1px!important;
    margin-bottom: 24px;
  }
  & p{
    margin-bottom: 12px!important;
  }
  & .exp_date{
    height: 56px;
    width: 51.5%;
    margin: 0 auto;
    border-radius: 30px;
    border: 1px solid #bdbdbd;
    margin-bottom: 65px;
  }
  .billing_address{
    margin-top: 45px;
    letter-spacing: 0.2px;
    margin-bottom: 23px;
  }
  .state{
    height: 56px;
    width: 51.5%;
    margin: 0 auto;
    border-radius: 30px;
    border: 1px solid #bdbdbd;
    margin-bottom: 12px;
    & select{
      height: 56px!important;
      text-align: center;
      width: 48%;
      background: rgba(0,0,0,0)!important;
      border: 0px solid white!important;
      text-align-last:center;
      & option{
        text-align-last:center;
        text-align: center;
      }
    }
  }
  .country{
    height: 56px;
    width: 51.5%;
    margin: 0 auto;
    border-radius: 30px;
    border: 1px solid #bdbdbd;
    & select{
      height: 56px!important;
      text-align: center;
      width: 48%;
      background: rgba(0,0,0,0)!important;
      border: 0px solid white!important;
      text-align-last:center;
      & option{
        text-align-last:center;
        text-align: center;
      }
    }
  }
}

#mm_field_cc_exp_month{
  height: 55px;
  margin-left: 20px;
  text-align: right;
  width: 50%;
  background-color: rgba(0,0,0,0);
  border: 0px solid white;
}

#mm_field_cc_exp_year{
  height: 55px;
  margin-left: 10px;
  text-align: left;
  width: 40%;
  background-color: rgba(0,0,0,0);
  border: 0px solid white;
}

.mm-login{
  @include media('xs') {
    width: 100%!important;
    max-width: 100%!important;
  }
  @include media('sm') {
    width: 100%;
    max-width: 100%!important;
  }
  @include media('md') {
    width: 600px;
  }
}

.wppb-login-wrap{
  & p{
    & input{
      border: 1px solid grey!important;
      border-radius: 50%;
    }
  }
}

.mm-login .mm-field{
  height: 56px;
  width: 420px!important;
  padding-left: 20px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid #bdbdbd;
}

.loc_select{
  height: 56px;
  width: 51.5%;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid #bdbdbd;
  & select{
    height: 56px!important;
    text-align: center;
    width: 48%;
    background: rgba(0,0,0,0)!important;
    border: 0px solid white!important;
    text-align-last:center;
    & option{
      text-align-last:center;
      text-align: center;
    }
  }
}

.mm-formField label{
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.mm-textField{
  width: 51.5%;
  display: block;
  height: 30px;
  margin: 1px;
  overflow: hidden;
  font-size: 1rem;
  min-height: 3.5rem !important;
  border-radius: 1.8125rem;
  border: 1px solid #bdbdbd;
  text-align: center;
  letter-spacing: 0.5px;
}

.mm-checkoutSection2{
  width: 584px;
  margin: 0 auto;
  height: 265px;
  padding-top: 34px;
  text-align: center;
  color: white;
  box-shadow: 0 0.125rem 1.875rem rgba(0, 0, 0, 0.04);
  border-radius: 0.8125rem;
  background-image: -webkit-linear-gradient(left, #ffbf40 0%, #ff801b 100%);
  background-image: -o-linear-gradient(left, #ffbf40 0%, #ff801b 100%);
  background-image: linear-gradient(to right, #ffbf40 0%, #ff801b 100%);

  & h4{
    color: white;
    text-transform: uppercase;
    font-size: 14px;
  }
  & #payment_total_bit{
    width: 63%;
    float: none!important;
    margin-left: auto!important;
    margin: 0 auto!important;
    & li{
      width: 50%;
      float: left!important;
      clear: none!important;
      & span{
        font-size: 16px;
        width: 100%;
      }
      & p{
        font-size: 44px;
        color: white;
        margin-top: 51px;
        font-weight: 600;
      }
    }
    & li::before{
      content: ""!important;
    }
  }
}

.mm-checkoutSection2 h2 .mm-data{
  font-size: 32px!important;
  letter-spacing: 0px;
}

.TandC_information{
  width: 584px;
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
}

.TandC_check{
  width: 584px;
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
}

.mm-paymentbuttons{
  width: 297px;
  margin: 0 auto;
  margin-top: 40px;
}

.ui-button{
  min-width: 0px!important;
  min-height: 0px!important;
}

.ui-dialog{
  max-width: 100%!important;
}

.passster-form {
    font-size: 16px;
}
